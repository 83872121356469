interface useVisibilityCheck
{
    checkIfVisible: (processElement: any, baseProcessModel: any) => boolean
}

enum SectionTypesEnum{
    BaseProcesses = 'BaseProcesses',
    Features = 'Features',
    Options = 'Options',
    AdditionalConfiguration = 'AdditionalConfiguration'
}

interface VisibilityConfiguration {
    sectionType: string;
    id: string;
    additionalConfigurationId: string;
    hiddenForValues: string[];
    visibleForValues: string[];
}

export function useVisibilityCheck(): useVisibilityCheck
{
    const sections = SectionTypesEnum;

    const checkIfVisible = (processElement: any, baseProcessModel: any) : boolean =>
    {
        let result = true;

        if (processElement.visibilityConfiguration && processElement.visibilityConfiguration.length > 0)
        {
            for (let index = 0; index < processElement.visibilityConfiguration.length; index++)
            {
                const visibilityConfiguration = <VisibilityConfiguration>processElement.visibilityConfiguration[index];

                if (visibilityConfiguration.sectionType == sections.BaseProcesses)
                {
                    const processesOption = baseProcessModel.baseProcessSection.baseProcesses;

                    const process = processesOption.find((x: any) => x.id == visibilityConfiguration.id);

                    if(process)
                    {
                        if (visibilityConfiguration.visibleForValues && visibilityConfiguration.visibleForValues.length > 0)
                            {
                                result = false;

                                if (visibilityConfiguration.visibleForValues.includes(process.value) || visibilityConfiguration.visibleForValues.includes(process.value?.toString()))
                                {
                                    result = true;

                                    return result;
                                }
                            }
                            else if (visibilityConfiguration.hiddenForValues && visibilityConfiguration.hiddenForValues.length > 0)
                            {
                                if (visibilityConfiguration.hiddenForValues.includes(process.value) || visibilityConfiguration.hiddenForValues.includes(process.value?.toString()))
                                    result = false;
                                else if (visibilityConfiguration.hiddenForValues.includes('null') && !process.value)
                                    result = false;
                            }
                    }
                }
                else if (visibilityConfiguration.sectionType == sections.Features)
                {
                    for (let index = 0; index < baseProcessModel.featuresSections.length; index++)
                    {
                        const featureSection = baseProcessModel.featuresSections[index];

                        const featuresOptions = featureSection.features;

                        const feature = featuresOptions.find((x: any) => x.id == visibilityConfiguration.id);

                        if(feature)
                        {
                            if (visibilityConfiguration.visibleForValues && visibilityConfiguration.visibleForValues.length > 0)
                                {
                                    result = false;

                                    if (visibilityConfiguration.visibleForValues.includes(feature.value) || visibilityConfiguration.visibleForValues.includes(feature.value?.toString()))
                                        result = true;
                                }
                                else if (visibilityConfiguration.hiddenForValues && visibilityConfiguration.hiddenForValues.length > 0)
                                {
                                    if (visibilityConfiguration.hiddenForValues.includes(feature.value) || visibilityConfiguration.hiddenForValues.includes(feature.value?.toString()))
                                        result = false;
                                    else if (visibilityConfiguration.hiddenForValues.includes('null') && !feature.value)
                                        result = false;
                                }
                        }
                    }
                }
                else if (visibilityConfiguration.sectionType == sections.Options)
                {
                    const options = baseProcessModel.optionsSection.options;

                    const option = options.find((x: any) => x.id == visibilityConfiguration.id);

                    if (option)
                    {
                        if (!checkIfVisible(option, baseProcessModel))
                            result = false;

                        else if (visibilityConfiguration.visibleForValues && visibilityConfiguration.visibleForValues.length > 0)
                        {
                            result = false;

                            if (visibilityConfiguration.visibleForValues.includes(option.value) || visibilityConfiguration.visibleForValues.includes(option.value?.toString()))
                                result = true;
                        }
                        else if (visibilityConfiguration.hiddenForValues && visibilityConfiguration.hiddenForValues.length > 0)
                        {
                            if (visibilityConfiguration.hiddenForValues.includes(option.value) || visibilityConfiguration.hiddenForValues.includes(option.value?.toString()))
                                result = false;
                            else if (visibilityConfiguration.hiddenForValues.includes('null') && !option.value)
                                result = false;
                        }
                    }
                }
                else if (visibilityConfiguration.sectionType == sections.AdditionalConfiguration)
                {
                    const options = baseProcessModel.optionsSection.options;

                    const option = options.find((x: any) => x.id == visibilityConfiguration.id);

                    if (option.additionalConfiguration && Array.isArray(option.additionalConfiguration))
                    {
                        const additionalConfiguration = option.additionalConfiguration.find(x => x.id == visibilityConfiguration.additionalConfigurationId);

                        if (additionalConfiguration)
                        {
                            if (!checkIfVisible(additionalConfiguration, baseProcessModel))
                                result = false;
                            else if (visibilityConfiguration.visibleForValues && visibilityConfiguration.visibleForValues.length > 0)
                            {
                                result = false;

                                if (visibilityConfiguration.visibleForValues.includes(additionalConfiguration.value) || visibilityConfiguration.visibleForValues.includes(additionalConfiguration.value?.toString()))
                                    result = true;

                            }
                            else if (visibilityConfiguration.hiddenForValues && visibilityConfiguration.hiddenForValues.length > 0)
                            {
                                if (visibilityConfiguration.hiddenForValues.includes(additionalConfiguration.value) || visibilityConfiguration.hiddenForValues.includes(additionalConfiguration.value?.toString()))
                                    result = false;
                                else if (visibilityConfiguration.hiddenForValues.includes('null') && !additionalConfiguration.value)
                                    result = false;
                            }
                        }

                    }
                }
            }
        }

        return result;
    };

    return { checkIfVisible };
}
