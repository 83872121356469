import { axios } from '@/plugins/axios';
import { Resource } from '@/helpers/Interfaces';

export enum CounterModuleName {
    receipt_warehouse = "receipt_warehouse",
    receipt_trade = "receipt_trade",
    direct_receipt_warehouse = "direct_receipt_warehouse",
    direct_receipt_trade = "direct_receipt_trade",
    transfer = "transfer",
    one_stage_issue = "one_stage_issue",
    collecting = "collecting",
    complaints = "complaints",
    complaint_receipt = "complaint_receipt",
    complaint_issue = "complaint_issue",
    packing = "packing",
    courier = "courier",
    personal_collection = "quality_control",
    inventories = "inventories"
}

export enum ProcessModuleEnum {
    receipt = "receipt",
    giveback = "giveback",
    direct_receipt = "direct_receipt",
    quick_transfers = "quick_transfers",
    zzl = "zzl",
    transfer = "transfer",
    quality_control = "quality_control",
    issue = "issue",
    collecting = "collecting",
    packing = "packing",
    courier = "courier",
    personal_collection = "personal_collection",
    personal_collection_issue = "personal_collection_issue",
    standard_inventories = "standard_inventories",
    product_inventories = "product_inventories",
    localization_inventories = "localization_inventories",
    inventories = "inventories",
    direct_issue = "direct_issue",
}

export default class ModulesService
{
    public static async getActiveStatus(): Promise<Resource<ProcessEnabledModel[]>>
    {
        return (await axios.get<Resource<ProcessEnabledModel[]>>('admin/modules/enablestatus')).data;
    }

    public static async getDocumentsCount(): Promise<Resource<DocumentCountModel[]>>
    {
        return (await axios.get<Resource<DocumentCountModel[]>>('admin/modules/countdocuments')).data;
    }

    public static async getDocumentsCountForModule(module: string): Promise<Resource<DocumentCountModel[]>>
    {
        return (await axios.get<Resource<DocumentCountModel[]>>(`admin/modules/countdocuments/${module}`)).data;
    }
}

export interface ProcessEnabledModel {
    moduleName: string;
    value: boolean;
}
export interface DocumentCountModel {
    module: string
    count: number
}
