import EmptyLayout from "@/views/layouts/EmptyLayout.vue";

export default [
    {
        // Konfiguracja
        path: "mes-configuration",
        redirect: "/error/404",
        component: EmptyLayout,
        children: [
            {
                path: "machine-failure",
                name: "mes-configuration-machine-failure",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/machinefailure/views/MachineFailureList.vue"
                    ),
            },
            {
                path: "machine-failure/add",
                name: "mes-configuration-machine-failure-add",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/machinefailure/views/MachineFailureForm.vue"
                    ),
            },
            {
                path: "machine-failure/:publicId",
                name: "mes-configuration-machine-failure-edit",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/machinefailure/views/MachineFailureForm.vue"
                    ),
            },
            {
                path: "machine-type-failure",
                name: "mes-configuration-machine-type-failure",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/machinetypefailure/views/MachineTypeFailureList.vue"
                    ),
            },
            {
                path: "machine-type-failure/add",
                name: "mes-configuration-machine-type-failure-add",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/machinetypefailure/views/MachineTypeFailureForm.vue"
                    ),
            },
            {
                path: "machine-type-failure/:publicId",
                name: "mes-configuration-machine-type-failure-edit",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/machinetypefailure/views/MachineTypeFailureForm.vue"
                    ),
            },
            {
                path: "machine-groups",
                name: "mes-configuration-machine-groups",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/machineGroups/views/MachineGroupsList.vue"
                    ),
            },
            {
                path: "machine-groups/add",
                name: "mes-configuration-machine-groups-add",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/machineGroups/views/MachineGroupsForm.vue"
                    ),
            },
            {
                path: "machine-groups/:publicId",
                name: "mes-configuration-machine-groups-edit",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/machineGroups/views/MachineGroupsForm.vue"
                    ),
            },
            {
                path: "units-of-measure",
                name: "mes-configuration-units-of-measure",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/unitsOfMeasure/views/UnitsOfMeasureList.vue"
                    ),
            },
            {
                path: "units-of-measure/add",
                name: "mes-configuration-units-of-measure-add",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/unitsOfMeasure/views/UnitsOfMeasureForm.vue"
                    ),
            },
            {
                path: "units-of-measure/:publicId",
                name: "mes-configuration-units-of-measure-edit",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/unitsOfMeasure/views/UnitsOfMeasureForm.vue"
                    ),
            },
            {
                path: "machines",
                name: "mes-configuration-machines",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/machines/views/MachinesList.vue"
                    ),
            },
            {
                path: "machines/add",
                name: "mes-configuration-machines-add",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/machines/views/MachinesForm.vue"
                    ),
            },
            {
                path: "machines/:publicId",
                name: "mes-configuration-machines-edit",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/machines/views/MachinesForm.vue"
                    ),
            },
            {
                path: "workplaces",
                name: "mes-configuration-workplaces",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/workplaces/views/WorkplacesList.vue"
                    ),
            },
            {
                path: "workplaces/add",
                name: "mes-configuration-workplaces-add",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/workplaces/views/WorkplacesForm.vue"
                    ),
            },
            {
                path: "workplaces/:publicId",
                name: "mes-configuration-workplaces-edit",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/workplaces/views/WorkplacesForm.vue"
                    ),
            },
            {
                path: "additional-tasks",
                name: "mes-configuration-additional-tasks",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/additionalTasks/views/AdditionalTasksList.vue"
                    ),
            },
            {
                path: "additional-tasks/add",
                name: "mes-configuration-additional-tasks-add",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/additionalTasks/views/AdditionalTasksForm.vue"
                    ),
            },
            {
                path: "additional-tasks/:publicId",
                name: "mes-configuration-additional-tasks-edit",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/additionalTasks/views/AdditionalTasksForm.vue"
                    ),
            },
            {
                path: "break-types",
                name: "mes-configuration-break-types",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/breakTypes/views/BreakTypesList.vue"
                    ),
            },
            {
                path: "break-types/add",
                name: "mes-configuration-break-types-add",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/breakTypes/views/BreakTypesForm.vue"
                    ),
            },
            {
                path: "break-types/:publicId",
                name: "mes-configuration-break-types-edit",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/breakTypes/views/BreakTypesForm.vue"
                    ),
            },
            {
                path: "additional-costs",
                name: "mes-configuration-additional-costs",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/additionalCosts/views/AdditionalCostsList.vue"
                    ),
            },
            {
                path: "additional-costs/add",
                name: "mes-configuration-additional-costs-add",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/additionalCosts/views/AdditionalCostsForm.vue"
                    ),
            },
            {
                path: "additional-costs/:publicId",
                name: "mes-configuration-additional-costs-edit",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/additionalCosts/views/AdditionalCostsForm.vue"
                    ),
            },
            {
                path: "operations",
                name: "mes-configuration-operations",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/operations/views/OperationsList.vue"
                    ),
            },
            {
                path: "operations/add",
                name: "mes-configuration-operations-add",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/operations/views/OperationsForm.vue"
                    ),
            },
            {
                path: "operations/:publicId",
                name: "mes-configuration-operations-edit",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/operations/views/OperationsForm.vue"
                    ),
            },
            {
                path: "mes-config/quality-control-parameters",
                name: "mes-configuration-quality-control-parameters",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/qualityControls/views/ParametersList.vue"
                    ),
            },
            {
                path: "mes-config/quality-control-parameters/add",
                name: "mes-configuration-quality-control-parameters-add",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/qualityControls/views/ParametersForm.vue"
                    ),
            },
            {
                path: "mes-config/quality-control-parameters/:publicId",
                name: "mes-configuration-quality-control-parameters-edit",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/qualityControls/views/ParametersForm.vue"
                    ),
            },
            {
                path: "mes-config/quality-control-plans",
                name: "mes-configuration-quality-control-plans",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/qualityControlPlans/views/PlansList.vue"
                    ),
            },
            {
                path: "mes-config/quality-control-plans/add",
                name: "mes-configuration-quality-control-plans-add",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/qualityControlPlans/views/PlansForm.vue"
                    ),
            },
            {
                path: "mes-config/quality-control-plans/:publicId",
                name: "mes-configuration-quality-control-plans-edit",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/qualityControlPlans/views/PlansForm.vue"
                    ),
            },
            {
                path: "shift-calendar",
                name: "mes-configuration-shift-calendar",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/shiftCalendar/views/ShiftConfiguration.vue"
                    ),
            },
            {
                path: "production-order-types",
                name: "mes-configuration-production-order-types",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/productionOrderTypes/views/OrderTypesList.vue"
                    ),
            },
            {
                path: "production-order-types/add",
                name: "mes-configuration-production-order-types-add",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/productionOrderTypes/views/OrderTypeForm.vue"
                    ),
            },
            {
                path: "production-order-types/:publicId",
                name: "mes-configuration-production-order-types-edit",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/productionOrderTypes/views/OrderTypeForm.vue"
                    ),
            },
            {
                path: "product-identifiers",
                name: "mes-configuration-product-identifiers",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/configuration/productIdentifiers/views/ProductIdentifiers.vue"
                    ),
            },
            // {
            //     path: 'serial-numbers',
            //     name: 'mes-configuration-serial-numbers',
            //     meta: { auth: true },
            //     component: () => import('@/modules/mes/configuration/serialNumbers/views/SerialNumbersConfiguration.vue')
            // },
            // {
            //     path: 'mes-batches',
            //     name: 'mes-configuration-batches',
            //     meta: { auth: true },
            //     component: () => import('@/modules/mes/configuration/batches/views/BatchesConfiguration.vue')
            // },
            {
                path: "operator-panel",
                name: "mes-operator-panel",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/operatorsPanel/views/OperatorPanelMain.vue"
                    ),
            },
            {
                path: "operator-panel/failure",
                name: "mes-operator-panel-failure",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/operatorsPanel/views/panelFailures/OperatorPanelFailures.vue"
                    ),
            },
            {
                path: "operator-panel/break",
                name: "mes-operator-panel-break",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/operatorsPanel/views/panelBreaks/OperatorPanelBreak.vue"
                    ),
            },
            {
                path: "operator-panel/tasks",
                name: "mes-operator-panel-tasks",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/operatorsPanel/views/panelTasks/OperatorPanelTasks.vue"
                    ),
            },
            {
                path: "operator-panel/operations",
                name: "mes-operator-panel-operations",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/operatorsPanel/views/panelOperations/OperatorPanelOperations.vue"
                    ),
            },
            {
                path: "operator-panel/operation/:id",
                name: "mes-operator-panel-operations-details",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/operatorsPanel/views/panelOperations/OperatorPanelOperationDetails.vue"
                    ),
            },
            {
                path: "production-recipes",
                name: "mes-recipes",
                meta: { auth: true },
                component: () =>
                    import("@/modules/mes/recipes/views/RecipesList.vue"),
            },
            {
                path: "production-recipes/add",
                name: "mes-recipes-add",
                meta: { auth: true },
                component: () =>
                    import("@/modules/mes/recipes/views/RecipeForm.vue"),
            },
            {
                path: "production-recipes/:publicId",
                name: "mes-recipes-edit",
                meta: { auth: true },
                component: () =>
                    import("@/modules/mes/recipes/views/RecipeDetails.vue"),
            },
            {
                path: "production-plans",
                name: "mes-plans",
                meta: { auth: true },
                component: () =>
                    import("@/modules/mes/plans/views/PlansList.vue"),
            },
            {
                path: "production-plans/add",
                name: "mes-plans-add",
                meta: { auth: true },
                component: () =>
                    import("@/modules/mes/plans/views/PlanForm.vue"),
            },
            {
                path: "production-plans/:publicId",
                name: "mes-plans-edit",
                meta: { auth: true },
                component: () =>
                    import("@/modules/mes/plans/views/PlanFormEdit.vue"),
            },
            {
                path: "production-orders",
                name: "mes-orders",
                meta: { auth: true },
                component: () =>
                    import("@/modules/mes/orders/views/OrdersList.vue"),
            },
            {
                path: "production-orders",
                name: "mes-orders-add",
                meta: { auth: true },
                component: () =>
                    import("@/modules/mes/orders/views/OrderForm.vue"),
            },
            {
                path: "production-orders/:publicId",
                name: "mes-orders-edit",
                meta: { auth: true },
                component: () =>
                    import("@/modules/mes/orders/views/OrderDetails.vue"),
            },
            {
                path: "production-customer-orders",
                name: "mes-customer-orders",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/customerOrder/views/CustomerOrderList.vue"
                    ),
            },
            {
                path: "production-customer-orders/:publicId",
                name: "mes-customer-order-info",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/customerOrder/views/CustomerOrderDocumentInfo.vue"
                    ),
            },
            {
                path: "additional-tasks-operators",
                name: "mes-additional-tasks-operators",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/operatorsAdditionalTask/views/AdditionalTaskOperatorList.vue"
                    ),
            },
            {
                path: "additional-tasks-operators/add",
                name: "mes-additional-tasks-operators-add",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/operatorsAdditionalTask/views/AdditionalTaskOperatorForm.vue"
                    ),
            },
            {
                path: "additional-tasks-operators/:publicId",
                name: "mes-additional-tasks-operators-edit",
                meta: { auth: true },
                component: () =>
                    import(
                        "@/modules/mes/operatorsAdditionalTask/views/AdditionalTaskOperatorEditForm.vue"
                    ),
            },
            {
                path: "production-failure",
                name: "mes-failure",
                meta: { auth: true },
                component: () =>
                    import("@/modules/mes/failure/views/FailureList.vue"),
            },
            {
                path: "production-failure/add",
                name: "mes-failure-add",
                meta: { auth: true },
                component: () =>
                    import("@/modules/mes/failure/views/FailureForm.vue"),
            },
            {
                path: "production-failure/:publicId",
                name: "mes-failure-edit",
                meta: { auth: true },
                component: () =>
                    import("@/modules/mes/failure/views/FailureForm.vue"),
            },
        ],
    },
    {
        // Kontrola jakości
        path: "quality-controls",
        redirect: "/error/404",
        component: EmptyLayout,
        children: [
            {
                path: 'list',
                name: 'mes-operator-panel-quality-controls',
                meta: { auth: true },
                component: () => import('@/modules/mes/operatorsPanel/views/panelQualityControls/QualityControlsList.vue'),
            },
            {
                path: 'details/:id',
                name: 'mes-operator-panel-quality-controls-details',
                meta: { auth: true },
                component: () => import('@/modules/mes/operatorsPanel/views/panelQualityControls/QualityControlsDetails.vue'),
            },
        ]
    },
];
