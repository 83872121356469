<template>
    <div v-if="props.ssccValidationEnabled && props.ssccValidationResult">
        <span class="text-danger mt-2" v-if="props.ssccValidationResult.notValidLength">{{ $t(`[[[Nieprawidłowa długość kodu dla SSCC]]]`) }}</span>
        <span class="text-danger mt-2" v-if="props.ssccValidationResult.withLetterOrSpecialCharacter">{{ $t(`[[[Kod SSCC musi zawierać tylko cyfry]]]`) }}</span>
        <div class="text-danger mt-2" v-if="props.ssccValidationResult.checkDigitIsDifferent">
            <span>{{ $t(`[[[Niewłaściwa cyfra kontrolna! Cyfra kontrolna dla kodu to: %0|||${props.ssccValidationResult.calculatedCheckDigit}]]]`) }}</span>
        </div>
        <div v-else-if="!props.ssccValidationResult.anyError" class="text-danger mt-2">
            <span>{{ $t(`[[[Cyfra kontrolna: %0|||${props.ssccValidationResult.calculatedCheckDigit}]]]`) }}</span>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ValidateCodeResult } from '@/modules/wms/common/services/Gs1Service';

interface Props {
    ssccValidationEnabled: boolean;
    ssccValidationResult: ValidateCodeResult | null;
}

const props = withDefaults(defineProps<Props>(), {});

</script>
