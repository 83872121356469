import { FormContract } from '@/components/forms/blueprints/form';
import { axios } from '@/plugins/axios';

export default class ModulesServices
{
    public static async getSitemap(): Promise<SitemapElement[]>
    {
        return (await axios.get<SitemapElement[]>(`admin/integration/additions/fronted/sitemap`)).data;
    }

    public static async getView(route: string): Promise<FrontendAdditionModel>
    {
        return (await axios.get<FrontendAdditionModel>(`admin/integration/additions/fronted/view/${route}`)).data;
    }

    public static async getPage(id: string): Promise<FrontendAdditionModel>
    {
        return (await axios.get<FrontendAdditionModel>(`admin/integration/additions/fronted/page/${id}`)).data;
    }


    public static async getComponents(model: ComponentQueryModel): Promise<FrontendAdditionModel>
    {
        return (await axios.get<FrontendAdditionModel>(`admin/integration/additions/fronted/components`, {
            params: { model }
        })).data;
    }
}

export interface SitemapElement {
    name: string
    route: string
    icon: string
    visible: boolean
    childrens: SitemapElement[]
}

export interface FrontendAdditionModel {
    name: string
    type: string
    code: string
    form: FormContract
}

export interface ComponentQueryModel {
    route: string;
    section: string;
}
