<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount, watch, nextTick } from 'vue';
import { useRouter, RouteRecordNormalized } from 'vue-router';
import { useDebounceFn } from '@vueuse/core';
import { usePermissions } from '@/plugins/permissions';
import { NodeItem, Option } from '@/helpers/Interfaces';
import SearchService, { SearchResult, SearchResultType, SearchResultGroup, SearchResultGroupValue } from '@/modules/core/common/services/SearchService';
import Pager from '@/helpers/Pager';
import Loader from '@/components/common/Loader.vue';
import { useLocalization } from '@/plugins/localization';
import { useSitemap } from '@/plugins/sitemap';
import { useLogging } from '@/plugins/logging';
import { useMedia } from '@/plugins/media';

defineOptions({
    name: 'Omnibox',
    components: {
        'loader': Loader
    }
});

const { $sitemap } = useSitemap();
const { $t } = useLocalization();
const { $log } = useLogging();
const { $permissions } = usePermissions();
const { mobile } = useMedia();

const input = ref<any>(null);

const router = useRouter();
const focused = ref(false);
const loading = ref(false);
const paths = ref<Option[]>([]);
const routes = ref<string[]>([]);
const redirects = ref<Record<string, string>>({});

const search = ref('');
const onlyMine = ref(false);
const allVersions = ref(false);
const pager = ref<any>(new Pager(1, 10));
const results = ref<SearchResult[]>([]);

const types = ref<NodeItem<string, SearchResultType>[]>([]);
const expandedTypes = ref<string[]>([null]);
const selectedTypes = ref<string[]>([]);

const groups = ref<NodeItem<string, SearchResultGroupValue>[]>([]);
const expandedGroups = ref<string[]>([null]);
const selectedValues = ref<string[]>([]);

const filteredPaths = computed(() =>
{
    if (search.value.length > 0)
    {
        return paths.value.filter(p =>
            p.text.toLowerCase().indexOf(search.value.toLowerCase()) >= 0
        ).slice(0, 5);
    }

    return [];
});

const visible = computed(() => focused.value && search.value.length > 0);
const hasResults = computed(() => results.value.length > 0 || types.value.length > 0);

onMounted(async () =>
{
    router.getRoutes().forEach(prepareRoutes);
    preparePaths(await $sitemap.all());

    document.addEventListener('click', onClick);
    window.addEventListener("keydown", onKeyDown);
});

onBeforeUnmount(() =>
{
    document.removeEventListener('click', onClick);
    window.removeEventListener("keydown", onKeyDown);
});

const children = (node: any): any[] =>
{
    return node.children.filter((p: any) => p.allowed);
};

const preparePaths = (items: any[], parent: Option = null): void =>
{
    for (const item of items)
    {
        const route = {
            value: item.route,
            text: $t(parent ? `${parent.text} / ${item.name}` : item.name),
            icon: item.icon ? item.icon : parent.icon
        };

        if (route.value && routes.value.includes(route.value))
        {
            route.value = router.resolve({ name: route.value });
            paths.value.push(route);
        }

        preparePaths(children(item), route);
    }
};

const prepareRoutes = (route: RouteRecordNormalized): void =>
{
    const params = route.path.split('/').filter(p => p.startsWith(':') && !p.endsWith('?'));

    if (route.name && params.length === 0)
    {
        routes.value.push(route.name.toString());
    }

    if (route.meta?.model)
    {
        redirects.value[route.meta.model as string] = route.name as string;
    }
};

const highlight = (text: string): string =>
{
    if (search.value.length > 0)
    {
        return text.replace(new RegExp(`(${search.value})`, 'ig'), (match, p1) => `<b>${p1}</b>`);
    }

    return text;
};

const routeUrl = (result: SearchResult): any =>
{
    return {
        name: redirects.value[result.type] ?? 'dashboard',
        params: { id: result.id }
    };
};

const clear = (): void =>
{
    onlyMine.value = false;
    allVersions.value = false;
    results.value = [];

    types.value = [];
    expandedTypes.value = [null];
    selectedTypes.value = [];

    groups.value = [];
    expandedGroups.value = [null];
    selectedValues.value = [];
};

const clearAll = (): void =>
{
    search.value = '';
    clear();
};

const open = (): void =>
{
    focused.value = true;

    nextTick(() =>
    {
        if (mobile())
            input.value?.focus();
    });
};

const close = (): void =>
{
    focused.value = false;
};

const onClick = (e: Event): void =>
{
    if (!e.composedPath().find(p => p.id === "omnibox"))
    {
        close();
    }
};

const onKeyDown = (event: KeyboardEvent): void =>
{
    if (focused.value && event.key === "Enter")
    {
        event.stopImmediatePropagation();
        event.stopPropagation();
        event.preventDefault();

        // performProductSearch();
        if ($permissions.all(["Ideo.Core.WMS.ProductsLocalizations.Application.Permissions.InformatorPermissions@View"]) && search.value)
            router.push({name:'wms-informator-search', query: {searchPhrase: search.value}});

    }
    else if (event.ctrlKey && event.key === "q")
    {
        event.stopImmediatePropagation();
        event.stopPropagation();
        event.preventDefault();
        input.value?.focus();
    }
};

const onInput = async (): Promise<void> =>
{
    // if (search.value)
    // {
    //     loading.value = true;

    //     if (search.value.length < 3)
    //     {
    //         clear();
    //     }
    //     else
    //     {
    //         await performSearch();
    //     }
    // }
};

const performSearch = useDebounceFn(async (): Promise<void> =>
{
    try
    {
        if (search.value.length >= 3)
        {
            const response = await SearchService.getSearchResults(
                search.value,
                selectedTypes.value,
                selectedValues.value.map(item => item.split(':') as [string, string]),
                onlyMine.value,
                allVersions.value,
                pager.value
            );

            pager.value.setTotalRows(response.totalRows);
            results.value = response.items;
            prepareTypes(response.meta.types ?? []);
            prepareGroups(response.meta.groups ?? []);
        }
    }
    catch (ex)
    {
        pager.value.setTotalRows(0);
        results.value = [];
        $log.error(ex);
    }
    finally
    {
        loading.value = false;
    }
}, 500);

// const performProductSearch = async (): Promise<void> =>
// {
//     try
//     {
//         if (search.value.length >= 3)
//         {
//             const response = await ProductsService.findProductByPhrase(search.value);

//             if (response)
//                 router.push({name:'wms-products-edit', params: {publicId: response.publicId}});

//             input.value.blur();
//             focused.value = false;
//         }
//     }
//     catch (ex)
//     {
//         pager.value.setTotalRows(0);
//         results.value = [];
//         $log.debug(ex);
//     }
// };


const prepareTypes = (typesData: SearchResultType[]): void =>
{
    types.value = [];
    expandedTypes.value = [null];

    typesData.forEach(p =>
    {
        const path = p.name.split(' / ');
        const nodes = path.map((q, i) => ({
            id: path.slice(0, i + 1).join(' / '),
            parentId: i > 0 ? path.slice(0, i).join(' / ') : null,
            hasChildren: false,
            resource: { ...p, name: q }
        }));

        nodes.forEach((n, i) =>
        {
            if (i < nodes.length - 1)
            {
                n.hasChildren = true;
                n.resource.count = 0;
                n.resource.key = n.parentId;
            }

            if (!expandedTypes.value.includes(n.parentId))
            {
                expandedTypes.value.push(n.parentId);
            }

            if (types.value.findIndex(p => p.id === n.id) === -1)
            {
                types.value.push(n as NodeItem<string, SearchResultType>);
            }
        });
    });
};

const prepareGroups = (groupsData: SearchResultGroup[]): void =>
{
    groups.value = [];
    expandedGroups.value = [null];

    groupsData.forEach(group =>
    {
        if (group.values.length > 0)
        {
            expandedGroups.value.push(group.key);

            groups.value.push({
                id: group.key,
                parentId: null,
                hasChildren: true,
                resource: group
            });

            group.values.forEach(value =>
            {
                groups.value.push({
                    id: value.key,
                    parentId: group.key,
                    hasChildren: false,
                    resource: value
                });
            });
        }
    });
};

watch(selectedTypes, () =>
{
    selectedValues.value = [];
    performSearch();
});

watch([onlyMine, allVersions, selectedValues], () =>
{
    performSearch();
});
</script>

<template>
    <div id="omnibox" class="d-flex align-items-stretch flex-fill">
        <div class="wrap" :class="{'focused': focused}">
            <button type="button" class="navbar-toggler ms-auto" @click="open">
                <i class="far fa-fw fa-magnifying-glass"></i>
            </button>
            <div class="input">
                <input v-model="search" type="text" class="form-control" :placeholder="$t('[[[Wyszukaj (Ctrl+Q)]]]')" ref="input" @focus="open" @input="onInput">
                <i class="icon fa-solid fa-magnifying-glass"></i>
                <i class="clear fa-solid fa-circle-xmark text-danger pointer" @click="clearAll" v-if="(search.length > 0)"></i>
            </div>
            <div class="popup" v-if="visible && filteredPaths.length > 0">
                <div class="results shadow scroll bg-body p-2 pb-0 rounded-2">
                    <div class="d-flex justify-content-center py-5 mb-2" v-if="filteredPaths.length == 0 && !hasResults && loading">
                        <loader></loader>
                    </div>
                    <div class="d-flex justify-content-center py-5 mb-2" v-if="filteredPaths.length == 0 && !hasResults && !loading">
                        {{ $t('[[[Nie znaleziono żadnych wyników.]]]') }}
                    </div>
                    <div class="list-group mb-2" v-if="filteredPaths.length > 0">
                        <router-link
                            class="list-group-item list-group-item-action pointer d-flex align-items-center"
                            v-for="(item, i) in filteredPaths"
                            :key="i"
                            :to="item.value"
                            @click="close"
                        >
                            <i class="fa fa-fw me-3" :class="item.icon"></i>
                            <span v-html="highlight(item.text)"></span>
                        </router-link>
                    </div>
                    <div class="row gx-2 mt-0" v-if="hasResults">
                        <div class="col-12 col-md-4">
                            <div class="alert alert-primary px-1 pt-2 pb-0 mb-2">
                                <ideo-form-checkbox v-model="onlyMine" :disabled="loading">
                                    {{ $t('[[[Pokaż tylko moje dokumenty]]]') }}<br>
                                    <small>{{ $t('[[[dodane i edytowane przeze mnie]]]') }}</small>
                                </ideo-form-checkbox>
                                <ideo-form-checkbox v-model="allVersions" :disabled="loading">
                                    {{ $t('[[[Szukaj we wszystkich wersjach]]]') }}<br>
                                    <small>{{ $t('[[[tylko dokumenty wersjonowane]]]') }}</small>
                                </ideo-form-checkbox>
                            </div>
                            <ideo-tree class="alert alert-secondary ps-1 pe-2 pt-1 mb-2" :pages="types" :expanded="expandedTypes" v-if="types.length > 0">
                                <template #toggle>&nbsp;</template>
                                <template #icon>&nbsp;</template>
                                <template #default="{node}">
                                    <template v-if="node.resource.count > 0">
                                        <span class="me-auto">
                                            <ideo-form-checkbox class="ms-n2 mb-n2" v-model="selectedTypes" :value="node.resource.key" :disabled="loading">
                                                {{ node.resource.name }}
                                            </ideo-form-checkbox>
                                        </span>
                                        <span class="badge rounded-pill text-bg-secondary text-white ms-2">
                                            {{ node.resource.count }}
                                        </span>
                                    </template>
                                    <span class="fw-bold" v-else>
                                        {{ node.resource.name }}
                                    </span>
                                </template>
                            </ideo-tree>
                            <ideo-tree class="alert alert-secondary ps-1 pe-2 pt-1 mb-2" :pages="groups" :expanded="expandedGroups" v-if="groups.length > 0">
                                <template #toggle>&nbsp;</template>
                                <template #icon>&nbsp;</template>
                                <template #default="{node}">
                                    <template v-if="node.parentId">
                                        <span class="me-auto">
                                            <ideo-form-checkbox class="ms-n2 mb-n2" v-model="selectedValues" :value="`${node.parentId}:${node.id}`" :disabled="loading">
                                                {{ node.resource.name }}
                                            </ideo-form-checkbox>
                                        </span>
                                        <span class="badge rounded-pill text-bg-secondary text-white ms-2">
                                            {{ node.resource.count }}
                                        </span>
                                    </template>
                                    <span class="fw-bold" v-else>
                                        {{ node.resource.name }}
                                    </span>
                                </template>
                            </ideo-tree>
                        </div>
                        <div class="col-12 col-md-8">
                            <div v-if="results.length > 0">
                                <router-link
                                    class="pointer d-block alert alert-light mb-2 p-2"
                                    v-for="(item, i) in results"
                                    :key="i"
                                    :to="routeUrl(item)"
                                    @click="close"
                                >
                                    <div class="d-flex w-100">
                                        <h5 class="mb-1 me-auto">{{ item.title }}</h5>
                                        <small v-if="item.dateModifiedUtc" :title="$t('[[[Aktualizacja: {0}|||%0]]]', $filters.datetime(item.dateModifiedUtc))">
                                            {{ item.dateModifiedUtc.toRelative() }}
                                        </small>
                                    </div>
                                    <div class="d-flex w-100">
                                        <small class="text-primary">{{ item.typeName }}</small>
                                        <span class="ms-auto" v-if="item.attachments.length > 0">
                                            <i class="fa-regular fa-paperclip-vertical" :title="$t('[[[Posiada załączniki]]]')"></i>
                                        </span>
                                    </div>
                                    <div class="d-flex w-100">
                                        <div class="mt-1 me-2" v-if="item.images.length == 1">
                                            <ideo-img :src="$filters.image(item.images[0].url, 'thumb')" thumbnail width="75" height="75" blank-color="#777" />
                                        </div>
                                        <div class="w-100">
                                            <p class="mb-1" v-if="item.description">
                                                {{ item.description }}
                                            </p>
                                            <div class="row row-cols-3 mt-1">
                                                <div class="col" v-for="(attrs, key) in item.attributes" :key="key">
                                                    <small>{{ key }}</small>
                                                    <div>
                                                        <span class="fs-6" v-for="(attr, a) in attrs" :key="a">{{ attr }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-1" v-if="item.images.length > 1">
                                                <span class="me-1" v-for="(img, i) in item.images" :key="i">
                                                    <ideo-img :src="$filters.image(img.url, 'thumb')" thumbnail width="35" height="35" blank-color="#777" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                            <div class="d-flex justify-content-center pt-5" v-else-if="loading">
                                <loader></loader>
                            </div>
                            <div class="d-flex justify-content-center pt-5" v-else>
                                <i class="fa-solid fa-ban fa-10x text-white"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
#omnibox {
    height: var(--ideo-header-height);

    .wrap {
        width: 100%;
        margin: auto 8px;

        .navbar-toggler {
            display: none;
        }

        .input {
            position: relative;

            input {
                padding-left: 36px;
                padding: 8px 8px 8px 36px;
            }
            .icon {
                position: absolute;
                top: 12px;
                left: 12px;
                color: var(--bs-body-color);
            }
            .clear {
                position: absolute;
                top: 12px;
                right: 12px;
            }
        }

        .popup {
            position: relative;

            .results {
                position: absolute;
                top: 4px;
                left: 0;
                right: -100%;
                max-height: 90vh;
                min-width: 824px;
                max-width: 1024px;
                border: 1px solid var(--bs-border-color);
                z-index: var(--ideo-dropdown-zindex);
            }
        }
    }
}

.desktop {
    #omnibox {
        .wrap {
            width: 50%;
        }
    }
}

.mobile {
    #omnibox {
        .wrap {
            .navbar-toggler {
                display: block;
            }
            .input {
                display: none;
            }

            &.focused {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                margin: 0;
                z-index: var(--ideo-dropdown-zindex);
                height: var(--ideo-header-height);
                background-color: var(--bs-body-bg);

                .navbar-toggler {
                    display: none;
                }
                .input {
                    display: block;
                    margin: 12px 10px 0 10px;
                }
                .popup {
                    .results {
                        position: fixed;
                        top: var(--ideo-header-height);
                        left: 0;
                        right: 0;
                        bottom: 0;
                        min-width: auto;
                        max-width: auto;
                        max-height: none;
                    }
                }
            }
        }
    }
}
</style>
