<template>
    <div class="input-wrapper" v-bind="elAttrs()">
        <div class="labeled-input" @click.stop="focusOnInput">
            <span v-if="label" class="input-label" :class="{ 'active': isFocused || currentValue, 'disabled': isDisabled }">
                {{ label }}
                <span class="fa-solid fa-fw fa-star-of-life fa-2xs text-danger" v-if="$props.required"></span>
            </span>
            <input
                v-model="currentValue"
                class="form-control"
                :class="{'clearable-input': clearable, 'active': isFocused || currentValue}"
                :disabled="isDisabled"
                :type="type"
                :min="min"
                :max="max"
                :name="name"
                :aria-label="label"
                @focus="onFocus"
                @blur="onBlur"
                ref="input"
                :step="step"
            >
            <div class="append-container">
                <slot name="append" v-if="hasAppendSlot"></slot>
                <button v-if="lockable" @click.prevent.stop="toggleField" type="button" class="lock button-reset" :class="{'locked': isDisabled || currentValue}" :title="isDisabled ? $t('[[[Odblokuj pole]]]') : $t('[[[Zablokuj pole]]]')">
                    <span class="fa-solid fa-lock gray-icon" v-if="isDisabled"></span>
                    <span class="fa-solid fa-lock-open gray-icon" v-else></span>
                </button>

                <i class="fa-solid fa-fw fa-circle-question text-info" v-if="help" id="input"></i>

                <ideo-popover class="popover" target="input" placement="right" triggers="hover" v-if="help">
                    <div>
                        {{ help }}
                    </div>
                </ideo-popover>
            </div>
        </div>
        <ideo-button variant="secondary" class="clear-button" :title="$t('[[[Wyczyść]]]')" v-if="clearable" @click.prevent="clear()" :disabled="isDisabled">
            <i class="fas fa-fw fa-times"></i>
        </ideo-button>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref, useSlots, nextTick, watch, onMounted, useAttrs } from 'vue';
import { only } from '@/helpers/Utils';

const props = withDefaults(defineProps<{
    modelValue: any,
    type?: string,
    min?: number,
    max?: number,
    name?: string,
    label: string,
    disabled?: boolean,
    lockable?: boolean,
    required?: boolean,
    clearable?: boolean,
    step?: string,
    help?:string
}>(), {
    type: 'text',
    min: 0,
    max: Number.MAX_VALUE,
    name: '',
    label: '',
    disabled: false,
    lockable: false,
    required: false,
    clearable: false,
    step: '0',
    help:''
});

defineOptions({
    name: 'labeled-form-input',
});

const emit = defineEmits<{
    (e: 'disabledField', {name, disabled}: {name: string, disabled: boolean}): void,
    (e: 'update:modelValue', value: any): void,
    (e: 'input', value: any): void
    (e: 'clear', inputName: any): void
}>();

const slots = useSlots();
const $attrs = useAttrs();

const elAttrs = (): Record<string, any> => only($attrs, 'class', 'style');

const input = ref<HTMLInputElement>(null);
const isFocused = ref(false);
const isDisabled = ref(false);
const hasAppendSlot = computed(() => !!slots.append);
const currentValue = computed<any>({
    get(): any
    {
        return props.modelValue;
    },
    set(value: any)
    {
        emit('update:modelValue', value);
        emit('input', value);
    }
});

onMounted(() =>
{
    isDisabled.value = props.disabled;
    checkInputFocus(props.modelValue);
    checkDisabledField();
});

function toggleField()
{
    isDisabled.value = !isDisabled.value;
    onDisabledField();

    if (isDisabled.value) isFocused.value = false;
    else
    {
        nextTick(() =>
        {
            focusOnInput();
        });
    }
}

function focusOnInput()
{
    nextTick(() =>
    {
        input.value.focus();
    });
}

function onDisabledField()
{
    emit('disabledField', {name: props.name, disabled: isDisabled.value});
}

function onFocus()
{
    isFocused.value = true;
}

function onBlur()
{
    if (input.value && !input.value.value)
        isFocused.value = false;

    if (input.value && input.value.value && input.value.value.length === 0)
        isFocused.value = false;

    return false;
}

function clear()
{
    currentValue.value = null;
    emit('clear', props.name);
}

function checkInputFocus(value: any)
{
    if (value === null || value === undefined || !String(value))
    {
        isFocused.value = false;
    }
    else
    {
        isFocused.value = true;
    }
}

function checkDisabledField()
{
    if (props.disabled) isDisabled.value = true;
}

watch(() => props.modelValue, (value: any) =>
{
    checkInputFocus(value);
});

watch(()=> props.disabled, (value: boolean) =>
{
    isDisabled.value = value;
});

defineExpose({
    focusOnInput,
    name: props.name,
    isDisabled,
    isFocused,
    modelValue: currentValue
});
</script>

<style lang="scss" scoped>
    .popover {
        z-index: 5;
    }

    .input-wrapper {
        position: relative;
        display: flex;
        align-items: stretch;
        width: 100%;

        .form-control {
            &.clearable-input {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        .clear-button {
            z-index: 1;
            margin-left: calc(var(--bs-border-width) * -1);
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .labeled-input {
        .append-container {
            position: absolute;
            right: 12px;
            top: 50%;
            z-index: 121;
            transform: translateY(-50%);
            display: flex;
            align-items: flex-end;
            gap: 20px;
            font-size: .875rem;

            &:deep(> *) {
                display: flex;
            }

            &:deep(button) {
                font-size: 1.25rem;
            }

            #layout.mobile & {
                gap: 40px;
                right: 25px;
            }
        }

        .form-control {
            height: 40px;
            font-weight: 500;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
            &[type=number] {
                appearance: textfield;
                -moz-appearance: textfield;
            }

            @media (min-width: 1200px) {
                height: 36px;
            }
        }
    }

    .form-control[type=time]:not(.active) {
        &::-webkit-datetime-edit-ampm-field,
        &::-webkit-datetime-edit-day-field,
        &::-webkit-datetime-edit-hour-field,
        &::-webkit-datetime-edit-millisecond-field,
        &::-webkit-datetime-edit-minute-field,
        &::-webkit-datetime-edit-month-field,
        &::-webkit-datetime-edit-second-field,
        &::-webkit-datetime-edit-week-field,
        &::-webkit-datetime-edit-year-field,
        &::-webkit-datetime-edit-text {
            color: transparent;
        }
    }
</style>
