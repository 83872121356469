import { Plugin, getCurrentInstance } from "vue";
import WmsSettingsService, { LicenceModel } from "@/modules/wms/configuration/settings/services/WmsSettingsService";
import { LicencesStoreModel, useLicencesStore } from "@/store/licenses";
import { RouteLocationNormalized } from "vue-router";
// import { useSitemap } from "./sitemap";

export interface LicenceHelper
{
    get(modules: Record<string, boolean>): Promise<Record<string, boolean>>;
    getApplicationAccess(modules: Record<string, boolean>): Promise<Record<string, boolean>>;
    getFull(): Promise<LicenceModel>;
    refresh(): Promise<void>;
    check(permissions : string[]): Promise<boolean>;
    // checkRoute(checkRoute: RouteLocationNormalized): Promise<boolean>;
}

class LicenceHelperImplementation implements LicenceHelper
{
    private licence: LicenceModel;
    private store: LicencesStoreModel;

    public constructor(store: LicencesStoreModel)
    {
        this.store = store;
        this.getFull();
    }

    public async get(modules: Record<string, boolean>): Promise<Record<string, boolean>>
    {
        if (!this.licence)
        {
            await this.refresh();
        }

        if (this.licence.wmsVisibility)
        {
            this.licence.wmsVisibility.forEach(module =>
            {
                modules[module.id] = module.value;
            });
        }

        if (this.licence.mesVisibility)
        {
            this.licence.wmsVisibility.forEach(module =>
            {
                modules[module.id] = module.value;
            });
        }


        return modules;
    }

    public async getApplicationAccess(modules: Record<string, boolean>): Promise<Record<string, boolean>>
    {
        if (!this.licence)
        {
            await this.refresh();
        }

        if (this.licence.applicationAccess)
        {
            this.licence.applicationAccess.forEach(module =>
            {
                modules[module.id] = module.value;
            });
        }

        return modules;
    }

    public async getFull(): Promise<LicenceModel>
    {
        if (!this.licence)
        {
            await this.refresh();
        }

        return this.licence;
    }

    public async refresh(): Promise<void>
    {
        const response = await WmsSettingsService.getLicence();

        this.store.licences = response.result;
        this.licence = response.result;
    }

    public async check(permissions : string[]) : Promise<boolean>
    {
        if (!this.licence)
        {
            await this.refresh();
        }

        for (let index = 0; index < permissions.length; index++)
        {
            const permissionId = permissions[index];

            const check = this.licence.wmsPermissions.find(x => x.id == permissionId);

            if (check?.value)
                return true;
        }

        return false;
    }

}

const LicencesPlugin: Plugin =
{
    install(app, options)
    {
        const store = useLicencesStore();

        app.config.globalProperties.$licence = new LicenceHelperImplementation(store);
    }
};

export const useLicences = () =>
{
    const app = getCurrentInstance();

    return {
        $licence: app.appContext.config.globalProperties.$licence
    };
};

export default LicencesPlugin;

declare module "@vue/runtime-core"
{
    interface ComponentCustomProperties
    {
        $licence: LicenceHelper;
    }
}
