<script lang="ts" setup>
import { computed, ref } from 'vue';
import { DateTime } from 'luxon';
import { useLocalization } from '@/plugins/localization';
import { useThemeStore } from '@/store/theme';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

type Flow = ('month' | 'year' | 'calendar' | 'time' | 'minutes' | 'hours' | 'seconds')[];

const model = defineModel<any|any[]>({
    get(value: any|any[])
    {
        if (value !== null && Array.isArray(value))
        {
            return value.map(p => typeof p === 'string' ? toDateTime(p) : toIso(p));
        }

        return typeof value === 'string' ? toDateTime(value) : toIso(value);
    },
    set(value: any|any[])
    {
        if (value !== null && Array.isArray(value))
        {
            return value.map(p => toDateTime(p));
        }

        return toDateTime(value);
    }
});

const props = withDefaults(defineProps<{
    name?: string,
    type?: 'date'|'datetime',
    zone?: 'local'|'UTC',
    disabled?: boolean,
    minuteStep?: number,
    placeholder?: string,
    inline?: boolean,
    range?: boolean,
    multi?: boolean,
    preview?: boolean
    position?: 'left'|'center'|'right',
    teleport?: boolean,
    maxDate?: any,
    minDate?: any,
    hideInputIcon?: boolean
    required?: boolean
    label?: string
    autoApply?: boolean
    flow?: Flow

}>(), {
    name: '',
    type: 'date',
    zone: 'local',
    disabled: false,
    minuteStep: 5,
    placeholder: null,
    inline: false,
    range: false,
    multi: false,
    preview: true,
    position: 'center',
    teleport: false,
    maxDate: null,
    minDate: null,
    hideInputIcon: false,
    required: false,
    label: '',
    autoApply: true,
    flow: null

});

const emit = defineEmits<{
    (e: 'closed'): void
}>();

const { $i18n } = useLocalization();
const store = useThemeStore();

const picker = ref(null);
const datepickerRef = ref(null);
const isDisabled = computed(() => props.disabled);
const format = computed(() => props.type === 'datetime' ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd');
const locale = computed(() => $i18n.locale());
const timezone = computed(() => props.zone == 'UTC' || props.type == 'date' ? 'UTC' : undefined);
const timepicker = computed(() => props.type == 'datetime');
const dark = computed(() => store.theme == 'dark');
const maxDate = computed(() => props.maxDate && props.maxDate instanceof DateTime ? props.maxDate.toISO() : props.maxDate);
const minDate = computed(() => props.minDate && props.minDate instanceof DateTime ? props.minDate.toISO() : props.minDate);
const flow = computed<Flow>(() => Array.isArray(props.flow) ? props.flow : timepicker.value ? ['calendar', 'time'] : ['calendar']);

function clear()
{
    picker.value?.dispatchEvent(new CustomEvent('input', { bubbles: true }));
}

function onClose()
{
    emit('closed');
}

function toIso(value: DateTime)
{
    return value instanceof DateTime ? value.toISO() : null;
}

function toDateTime(value: string)
{
    let date = value ? DateTime.fromISO(value).setZone('UTC') : null;

    if (props.type == 'date' && date !== null)
    {
        date = date.set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
        });
    }

    return date;
}

defineExpose({
    datepickerRef,
    name: props.name,
    isDisabled,
});
</script>

<template>
    <div :class="{'labeled-input': label}">
        <div class="input-label datetime-label" :class="{'active': model}" v-if="label">
            <span>
                {{ label }}
                <span class="fa-solid fa-fw fa-star-of-life fa-2xs text-danger" v-if="props.required"></span>
            </span>
        </div>
        <VueDatePicker
            v-model="model"
            model-type="iso"
            :timezone="timezone"
            :format="format"
            :locale="locale"
            :cancel-text="$t('[[[Anuluj]]]')"
            :select-text="$t('[[[Wybierz]]]')"
            :now-button-label="$t('[[[Teraz]]]')"
            :placeholder="props.placeholder"
            :enable-time-picker="timepicker"
            :minutes-increment="props.minuteStep"
            :minutes-grid-increment="props.minuteStep"
            :start-time="{minutes: Math.floor(DateTime.now().minute / 5) * 5}"
            :inline="props.inline"
            :range="props.range"
            :multi-calendars="props.multi"
            :dark="dark"
            :disabled="props.disabled"
            :auto-apply="props.autoApply"
            :position="props.position"
            :teleport="props.teleport"
            :max-date="maxDate"
            :min-date="minDate"
            :flow="flow"
            :partial-flow="true"
            :hide-input-icon="props.hideInputIcon"
            @cleared="clear"
            :config="{setDateOnMenuClose: true}"
            @closed="onClose"
            ref="datepickerRef"
        >
            <template #trigger v-if="!!$slots.trigger">
                <slot name="trigger"></slot>
            </template>
            <template #clear-icon="{ clear }" v-if="!!$slots['clear-icon']">
                <slot name="clear-icon" :clear="clear"></slot>
            </template>
        </VueDatePicker>
        <input type="hidden" :name="props.name" ref="picker">
        <div class="text-center small" v-if="model && props.preview && !timezone && !props.range">
            {{ $filters.date(model as string, format, 'UTC') }} (UTC)
        </div>
    </div>
</template>

<style scoped lang="scss">
:deep(.dp__input) {
    margin-top: -1px;
    padding-top: 7px;
    padding-bottom: 7px;
    background-color: var(--input-bg);

    &:disabled {
        background: var(--bs-secondary-bg);
    }
}
</style>
