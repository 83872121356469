<template>
    <div class="input-wrapper" v-bind="elAttrs()">
        <div class="labeled-input">
            <span v-if="label" class="input-label" :class="{ 'active': isFocused || currentValue, 'disabled': isDisabled }">
                {{ label }}
                <span class="fa-solid fa-fw fa-star-of-life fa-2xs text-danger" v-if="$props.required"></span>
            </span>
            <textarea
                v-model="currentValue"
                class="form-control"
                :class="props.class"
                :disabled="isDisabled"
                :name="name"
                :aria-label="label"
                :ref="textarea"
                @focus="onFocus"
                @blur="onBlur"
                v-bind="inputAttrs()"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch, onMounted, useAttrs } from 'vue';
import { except, only } from '@/helpers/Utils';

const props = withDefaults(defineProps<{
    modelValue: any,
    type?: string,
    min?: number,
    name?: string,
    label: string,
    disabled?: boolean,
    lockable?: boolean,
    required?: boolean,
    clearable?: boolean,
    step?: string,
    class?: string
}>(), {
    type: 'text',
    min: 0,
    name: '',
    label: '',
    disabled: false,
    lockable: false,
    required: false,
    clearable: false,
    step: '0',
    class: null
});

defineOptions({
    name: 'labeled-form-textarea',
});

const emit = defineEmits<{
    (e: 'disabledField', {name, disabled}: {name: string, disabled: boolean}): void,
    (e: 'update:modelValue', value: any): void,
    (e: 'input', value: any): void
    (e: 'change', value: any): void
}>();

const $attrs = useAttrs();

const inputAttrs = (): Record<string, any> => except($attrs, 'class', 'style');
const elAttrs = (): Record<string, any> => only($attrs, 'class', 'style');

const textarea = ref<HTMLTextAreaElement>(null);
const isFocused = ref(false);
const isDisabled = ref(false);
const currentValue = computed<any>({
    get(): any
    {
        return props.modelValue;
    },
    set(value: any)
    {
        emit('update:modelValue', value);
        emit('change', value);
        emit('input', value);
    }
});

onMounted(() =>
{
    checkInputFocus(props.modelValue);
    checkDisabledField();
});

function onFocus()
{
    isFocused.value = true;
}

function onBlur()
{
    if (!textarea.value)
        isFocused.value = false;

    return false;
}

function checkInputFocus(value: any)
{
    if (!(value === null || value === undefined || !String(value)))
    {
        isFocused.value = true;
    }
}

function checkDisabledField()
{
    if (props.disabled) isDisabled.value = true;
}

watch(() => props.modelValue, (value: any) =>
{
    checkInputFocus(value);
});

watch(()=> props.disabled, (value: boolean) =>
{
    isDisabled.value = value;
});

defineExpose({
    name: props.name,
    isDisabled,
    isFocused
});
</script>

<style lang="scss" scoped>
    .input-wrapper {
        position: relative;
        display: flex;
        align-items: stretch;
        width: 100%;

        .form-control {
            &.clearable-input {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        .clear-button {
            z-index: 1;
            margin-left: calc(var(--bs-border-width) * -1);
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .labeled-input {
        .append-container {
            position: absolute;
            right: 12px;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-items: flex-end;
            gap: 20px;
            font-size: .875rem;

            &:deep(> *) {
                display: flex;
            }

            &:deep(button) {
                font-size: 1.25rem;
            }

            #layout.mobile & {
                gap: 40px;
                right: 25px;
            }
        }

        .form-control {
            font-weight: 500;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
            &[type=number] {
                appearance: textfield;
                -moz-appearance: textfield;
            }
        }

        .input-label {
            top: 19px;
        }
    }
</style>