<script lang="ts" setup>
import { computed, ref } from 'vue';

const emit = defineEmits<{
    (e: 'changed'): void
}>();

const model = defineModel<any>();
const props = withDefaults(defineProps<{
    size?: 'sm'|'md'|'lg',
    chars?: number
}>(), {
    size: 'md',
    chars: 20
});

const input = ref<HTMLInputElement>(null);

const customClasses = computed(() => ({
    'form-control': true,
    'form-control-sm': props.size == 'sm',
    'form-control-lg': props.size == 'lg'
}));

const onCheckedChanged = () =>
{
    emit('changed');
};

defineExpose({
    focus: () => input.value.focus()
});
</script>

<template>
    <input ref="input" v-model="model" :class="customClasses" :size="props.chars" @input="onCheckedChanged">
</template>
