<template>
    <div
        :class="{
            'ideo-multiselect': props.showReset,
            'wms-dropdown labeled-input': props.labeledInput,
            'focused': opened,
            'disabled' : props.disabled}"
    >
        <div class="input-label" :class="{'active': opened || model}" v-if="props.labeledInput">
            <span>
                {{ labeledInput }}
                <span class="fa-solid fa-fw fa-star-of-life fa-2xs text-danger" v-if="props.required"></span>
            </span>
        </div>
        <multiselect
            v-model="model" :options="props.options" :name="name" :loading="loading" :label="props.label" :track-by="props.trackBy"
            :placeholder="props.placeholder" :select-label="''" :selected-label="''" :deselect-label="''" :multiple="props.multiple" :searchable="props.searchable" :options-limit="props.limit"
            :internal-search="false" :clear-on-select="false" :close-on-select="true" :max-height="300" :show-no-results="true" :hide-selected="false" :disabled="props.disabled" :allow-empty="props.allowEmpty" @select="onSelected" @open="open()" @close="close()"
        >
            <template #noOptions>{{ $t('[[[Lista jest pusta]]]') }}</template>
            <template #noResult>{{ $t('[[[Nie znaleziono żadnych wyników.]]]') }}</template>
            <template #singleLabel="{ option }"><slot name="selected" :option="option"></slot></template>
            <template #option="{ option }"><slot name="option" :option="option"></slot></template>
        </multiselect>
        <span v-if="props.showReset" class="btn btn-secondary btn-close bg-light" @click="reset()" />
    </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { Option } from '@/helpers/Interfaces';
import Multiselect from 'vue-multiselect/src/Multiselect.vue';

type ValueType = null|string|number|string[]|number[];

const props = withDefaults(defineProps<{
    name?: string,
    modelValue: ValueType,
    limit?: number,
    disabled?: boolean;
    refresh?: boolean,
    required?: boolean,
    trackBy?: string,
    label?: string,
    labeledInput?: string
    placeholder?: string,
    multiple?: boolean
    showReset?: boolean
    searchable?: boolean
    options: Option[]
    allowEmpty?: boolean;
}>(), {
    name: '',
    modelValue: null,
    limit: 10,
    disabled: false,
    refresh: false,
    required: false,
    trackBy: 'value',
    label: 'text',
    labeledInput: '',
    placeholder: '',
    multiple: false,
    showReset: false,
    searchable: true,
    options: () => [],
    allowEmpty: true,
});

const emit = defineEmits<{
    (e: 'update:modelValue', value: ValueType): void
    (e: 'changed', value: Option | Option[]): void
    (e: 'select', value: Option | Option[]): void
}>();

const model = ref<Option | Option[]>(null);
const loading = ref(false);
const opened = ref(false);

function open()
{
    opened.value = true;
}

function close()
{
    opened.value = false;
}

function updateModel(selectedModel: Option | Option[])
{
    let value: ValueType;

    if (props.multiple)
    {
        if (selectedModel != null && Array.isArray(selectedModel))
        {
            value = selectedModel.map((element: Option) => element[props.trackBy]);
        }
        else value = null;
    }
    else
    {
        value = selectedModel != null && props.trackBy in selectedModel ? selectedModel[props.trackBy] : null;
    }

    emit('update:modelValue', value);
}

function onSelected()
{
    emit('select', model.value);
}

function reset()
{
    model.value = null;

    updateModel(model.value);
}

watch(() => props.options, (value: Option[]) =>
{
    if (value && value.length)
    {
        if (props.modelValue)
        {
            model.value = value.find(x => x[props.trackBy] == props.modelValue) ?? null;
        }
    }
}, { immediate: true });

watch(model, (value: Option[]) =>
{
    updateModel(value);
    emit('changed', value);
});
</script>

<style lang="scss" scoped>
.input-wrapper {
    &.focused {
        position: relative;
        z-index: 121;
    }
}
</style>
