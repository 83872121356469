import { axios } from '@/plugins/axios';


export default class Gs1Service
{
    public static async calculateCheckDigit(model: ValidateCodeModel): Promise<CalculateCheckDigitResult>
    {
        return (await axios.post<CalculateCheckDigitResult>('admin/gs1/calcualate-check-digit', model)).data;
    }

    public static async validateGtinCode(model: ValidateCodeModel): Promise<ValidateCodeResult>
    {
        return (await axios.post<ValidateCodeResult>('admin/gtin/validate-code', model)).data;
    }

    public static async validateGlnCode(model: ValidateCodeModel): Promise<ValidateCodeResult>
    {
        return (await axios.post<ValidateCodeResult>('admin/gln/validate-code', model)).data;
    }

    public static async validateSsccCode(model: ValidateCodeModel): Promise<ValidateCodeResult>
    {
        return (await axios.post<ValidateCodeResult>('admin/sscc/validate-code', model)).data;
    }

    public static async generateSsccCode(): Promise<GenerateSsccCodeResult>
    {
        return (await axios.get<GenerateSsccCodeResult>('admin/sscc/generate')).data;
    }
    
    public static async parseGs1Code(code: string): Promise<ParseCodeResult>
    {
        return (await axios.post<ParseCodeResult>('admin/gs1/parse-code', {
            code
        })).data;
    }
}

export interface CalculateCheckDigitResult {
    result: number;
}

export interface ValidateCodeModel {
    code: string;
}

export interface ValidateCodeResult {
    anyError: boolean;
    notValidLength: boolean;
    emptyValue: boolean;
    withLetterOrSpecialCharacter: boolean;
    checkDigitIsDifferent: boolean;
    currentCheckDigit: number;
    calculatedCheckDigit: number;
}

export interface GenerateSsccCodeResult
{
    checkDigit: number;
    gs1CompanyPrefix: string;
    lastNumber: number;
    supplementaryDigit: number;
    number: string;
}

export interface ParseCodeResult {
    carrierCode: string
    productCode: string
    batchCode: string
    batchExpirationDateUtc: string
}
