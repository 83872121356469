export default [
    {
        name: '[[[Strona główna]]]',
        short: '[[[Start]]]',
        route: 'dashboard',
        icon: 'fas fa-fw fa-home'
    },
    {
        name: '[[[Moje konto]]]',
        icon: 'fas fa-fw fa-user',
        children: [
            {
                name: '[[[Profil użytkownika]]]',
                route: 'core-account-profile',
                icon: 'fa fa-fw fa-user-circle'
            },
            {
                name: '[[[Zmiana hasła]]]',
                route: 'core-account-password',
                icon: 'fa fa-fw fa-toggle-on'
            },
            {
                name: '[[[Centrum powiadomień]]]',
                route: 'core-account-notifications',
                icon: 'far fa-fw fa-envelope-open',
                query: {status: 'unreaded'},
                event: 'notifications::reload-list'
            },
            {
                name: '[[[Historia logowania]]]',
                route: 'core-account-security',
                icon: 'far fa-fw fa-phone-laptop'
            },
            {
                name: '[[[Dashboardy]]]',
                route: 'core-dashboards',
                icon: 'far fa-display-chart-up',
                auth: { all: ['DashboardPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Dodanie dashboardu]]]',
                        route: 'core-dashboards-add',
                        auth: { all: ['DashboardPermissions@Edit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja dashboardu]]]',
                        route: 'core-dashboards-edit',
                        auth: { all: ['DashboardPermissions@Edit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja aranżacji dashboardu]]]',
                        route: 'core-dashboards-arrangement',
                        auth: { all: ['DashboardPermissions@Edit'], any: [] },
                        visible: false
                    }
                ]
            }
        ]
    },
    // {
    //     name: '[[[Usługi]]]',
    //     icon: 'fas fa-fw fa-server',
    //     namespace: 'Ideo.Core.Common.Application.Permissions.Saas',
    //     children: [
    //         {
    //             name: '[[[Klienci]]]',
    //             route: 'saas-tenants',
    //             icon: 'fas fa-fw fa-users-class',
    //             auth: { all: ['TenantsPermissions@View'], any: [] },
    //             children: [
    //                 {
    //                     name: '[[[Dodanie klienta]]]',
    //                     route: 'saas-tenants-add',
    //                     auth: { all: ['TenantsPermissions@Edit'], any: [] },
    //                     visible: false
    //                 },
    //                 {
    //                     name: '[[[Edycja klienta]]]',
    //                     route: 'saas-tenants-edit',
    //                     auth: { all: ['TenantsPermissions@Edit'], any: [] },
    //                     visible: false
    //                 }
    //             ]
    //         },
    //         {
    //             name: '[[[Bazy danych]]]',
    //             route: 'saas-databases',
    //             icon: 'fas fa-fw fa-database',
    //             auth: { all: ['DatabasesPermissions@View'], any: [] },
    //             children: [
    //                 {
    //                     name: '[[[Dodanie serwera]]]',
    //                     route: 'saas-databases-add',
    //                     auth: { all: ['DatabasesPermissions@Edit'], any: [] },
    //                     visible: false
    //                 },
    //                 {
    //                     name: '[[[Edycja serwera]]]',
    //                     route: 'saas-databases-edit',
    //                     auth: { all: ['DatabasesPermissions@Edit'], any: [] },
    //                     visible: false
    //                 }
    //             ]
    //         },
    //         {
    //             name: '[[[Serwery plików]]]',
    //             route: 'saas-storages',
    //             icon: 'fas fa-fw fa-hdd',
    //             auth: { all: ['StoragesPermissions@View'], any: [] },
    //             children: [
    //                 {
    //                     name: '[[[Dodanie serwera]]]',
    //                     route: 'saas-storages-add',
    //                     auth: { all: ['StoragesPermissions@Edit'], any: [] },
    //                     visible: false
    //                 },
    //                 {
    //                     name: '[[[Edycja serwera]]]',
    //                     route: 'saas-storages-edit',
    //                     auth: { all: ['StoragesPermissions@Edit'], any: [] },
    //                     visible: false
    //                 }
    //             ]
    //         }
    //     ]
    // },
    {
        name: '[[[Administracja]]]',
        icon: 'fas fa-fw fa-desktop',
        namespace: 'Ideo.Core.Common.Application.Permissions.Core',
        children: [
            {
                name: '[[[Użytkownicy]]]',
                route: 'core-users',
                icon: 'fa fa-fw fa-user',
                auth: { all: ['UsersPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Dodanie użytkownika]]]',
                        route: 'core-users-add',
                        auth: { all: ['UsersPermissions@Edit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja użytkownika]]]',
                        route: 'core-users-edit',
                        auth: { all: ['UsersPermissions@Edit'], any: [] },
                        visible: false
                    }
                ]
            },
            {
                name: '[[[Role i uprawnienia]]]',
                route: 'core-roles',
                icon: 'far fa-fw fa-user-shield',
                auth: { all: ['RolesPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Dodanie roli]]]',
                        route: 'core-roles-add',
                        auth: { all: ['RolesPermissions@Edit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja roli]]]',
                        route: 'core-roles-edit',
                        auth: { all: ['RolesPermissions@Edit'], any: [] },
                        visible: false
                    }
                ]
            },
            {
                name: '[[[Ustawienia]]]',
                icon: 'fas fa-fw fa-cogs',
                children: [
                    // {
                    //     name: '[[[Konta użytkowników]]]',
                    //     route: 'core-accounts',
                    //     icon: 'fas fa-fw fa-users',
                    //     auth: { all: ['AdminPermissions@Accounts'], any: [] }
                    // },
                    {
                        name: '[[[Bezpieczeństwo]]]',
                        route: 'core-security',
                        icon: 'fas fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Security'], any: [] }
                    },
                    // {
                    //     name: '[[[Zaufane adresy IP]]]',
                    //     route: 'core-trusted-ips',
                    //     icon: 'far fa-fw fa-shield-alt',
                    //     auth: { all: ['AdminPermissions@TrustedIPs'], any: [] }
                    // },
                    {
                        name: '[[[ReCaptcha]]]',
                        route: 'core-recaptcha',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Recaptcha'], any: [] }
                    },
                    {
                        name: '[[[Poczta]]]',
                        route: 'core-mailing',
                        icon: 'fa-regular fa-envelope',
                        auth: { all: ['AdminPermissions@Mailing'], any: [] }
                    },
                    {
                        name: '[[[Szablony powiadomień]]]',
                        route: 'core-notifications-templates',
                        icon: 'far fa-fw fa-ballot-check',
                        auth: { all: ['NotificationsPermissions@Access'], any: [] },
                        children: [
                            {
                                name: '[[[Lista szablonów]]]',
                                route: 'core-notifications-templates',
                                auth: { all: ['NotificationsPermissions@Access'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Dodaj szablon]]]',
                                route: 'core-notifications-templates-add',
                                auth: { all: ['NotificationsPermissions@Access'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edytuj szablon]]]',
                                route: 'core-notifications-templates-edit',
                                auth: { all: ['NotificationsPermissions@Access'], any: [] },
                                visible: false,
                            },
                        ],
                    },
                    {
                        name: "[[[Ustawienia powiadomień PUSH]]]",
                        route: "core-one-signal-settings",
                        icon: "fas fa-fw fa-shield-alt",
                        auth: { all: ["AdminPermissions@Mailing"], any: [] },
                    },
                    // {
                    //     name: '[[[Centrum powiadomień]]]',
                    //     route: 'core-notifications-collective',
                    //     icon: 'far fa-fw fa-ballot-check',
                    //     auth: { all: ['CollectiveNotificationsPermissions@Access'], any: [] },
                    //     children: [
                    //         {
                    //             name: '[[[Lista powiadomień zbiorowych]]]',
                    //             route: 'core-notifications-collective',
                    //             auth: { all: ['CollectiveNotificationsPermissions@Access'], any: [] },
                    //             visible: false
                    //         },
                    //         {
                    //             name: '[[[Dodaj powiadomienie zbiorowe]]]',
                    //             route: 'core-notifications-collective-add',
                    //             auth: { all: ['CollectiveNotificationsPermissions@Access'], any: [] },
                    //             visible: false
                    //         },
                    //         {
                    //             name: '[[[Edytuj powiadomienie zbiorowe]]]',
                    //             route: 'core-notifications-collective-edit',
                    //             auth: { all: ['CollectiveNotificationsPermissions@Access'], any: [] },
                    //             visible: false,
                    //         }
                    //     ]
                    // },
                    {
                        name: '[[[Źródła LDAP]]]',
                        route: 'core-ldap',
                        icon: 'fas fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@LdapConfig'], any: [] },
                        children: [
                            {
                                name: '[[[Dodanie źródła]]]',
                                route: 'core-ldap-add',
                                auth: { all: ['AdminPermissions@LdapConfig'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja źródła]]]',
                                route: 'core-ldap-edit',
                                auth: { all: ['AdminPermissions@LdapConfig'], any: [] },
                                visible: false
                            }
                        ]
                    },
                    // {
                    //     name: '[[[Ustawienia sesji]]]',
                    //     route: 'core-session-settings',
                    //     icon: 'fas fa-fw fa-shield-alt',
                    //     auth: { all: ['AdminPermissions@Session'], any: [] }
                    // },
                    {
                        name: '[[[Paleta kolorów]]]',
                        route: 'core-colors',
                        icon: 'fas fa-fw fa-palette',
                        auth: { all: ['AdminPermissions@Colors'], any: [] }
                    },
                    // {
                    //     name: '[[[Sztuczna inteligencja]]]',
                    //     route: 'core-ai',
                    //     icon: 'far fa-fw fa-shield-alt',
                    //     auth: { all: ['AdminPermissions@Ai'], any: [] }
                    // },
                    // {
                    //     name: '[[[Tłumaczenia]]]',
                    //     route: 'core-translations',
                    //     icon: 'far fa-fw fa-subtitles',
                    //     auth: { all: ['AdminPermissions@Translations'], any: [] }
                    // },
                    {
                        name: '[[[Licencja]]]',
                        route: 'core-licences-token',
                        icon: 'fas fa-fw fa-palette',
                        auth: { all: ['AdminPermissions@LicencesToken'], any: [] }
                    },
                    // {
                    //     name: '[[[Asystent AI]]]',
                    //     route: 'core-ai',
                    //     icon: 'far fa-fw fa-shield-alt',
                    //     auth: { all: ['AdminPermissions@Ai'], any: [] },
                    // },
                    // {
                    //     name: '[[[Tłumaczenia]]]',
                    //     route: 'core-translations',
                    //     icon: 'far fa-fw fa-subtitles',
                    //     auth: { all: ['AdminPermissions@Translations'], any: [] }
                    // },
                    // {
                    //     name: '[[[Monitoring]]]',
                    //     route: 'core-monitoring',
                    //     icon: 'far fa-fw fa-shield-alt',
                    //     auth: { all: ['AdminPermissions@Monitoring'], any: [] }
                    // },
                    // {
                    //     name: '[[[Asystent]]]',
                    //     route: 'core-ai-assistant-add',
                    //     icon: 'far fa-fw fa-shield-alt',
                    //     auth: { all: ['AdminPermissions@Ai'], any: [] },
                    //     visible: false
                    // },
                    // {
                    //     name: '[[[Asystent]]]',
                    //     route: 'core-ai-assistant-edit',
                    //     icon: 'far fa-fw fa-shield-alt',
                    //     auth: { all: ['AdminPermissions@Ai'], any: [] },
                    //     visible: false
                    // },
                    // {
                    //     name: '[[[Pliki Asystenta]]]',
                    //     route: 'core-ai-assistant-file-add',
                    //     icon: 'far fa-fw fa-shield-alt',
                    //     auth: { all: ['AdminPermissions@Ai'], any: [] },
                    //     visible: false
                    // },
                    // {
                    //     name: '[[[Pliki Asystent]]]',
                    //     route: 'core-ai-assistant-file-edit',
                    //     icon: 'far fa-fw fa-shield-alt',
                    //     auth: { all: ['AdminPermissions@Ai'], any: [] },
                    //     visible: false
                    // },
                    {
                        name: '[[[Logi]]]',
                        route: 'core-logs',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Logs'], any: [] },
                        children: [
                            {
                                name: '[[[Edycja logów]]]',
                                route: 'core-logs-edit',
                                auth: { all: ['AdminPermissions@Logs'], any: [] },
                                visible: false
                            }
                        ]
                    }
                    // {
                    //     name: '[[[Sztuczna inteligencja]]]',
                    //     route: 'core-ai-settings'
                    // }
                ]
            },
            {
                name: '[[[Monitoring]]]',
                icon: 'fas fa-fw fa-analytics',
                children: [
                    {
                        name: '[[[Historia logowania]]]',
                        route: 'core-authlog',
                        icon: 'fas fa-fw fa-file-import',
                        auth: { all: ['AdminPermissions@Authlog'], any: [] }
                    },
                    {
                        name: '[[[Rejestr zmian]]]',
                        route: 'core-changelog',
                        icon: 'fas fa-fw fa-file-signature',
                        auth: { all: ['AdminPermissions@Changelog'], any: [] },
                        children: [
                            {
                                name: '[[[Szczegóły zdarzenia]]]',
                                route: 'core-changelog-diff',
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Harmonogram zadań]]]',
                        route: 'core-scheduler',
                        icon: 'fas fa-fw fa-clock',
                        auth: { all: ['SchedulerPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Historia zadania]]]',
                                route: 'core-scheduler-history',
                                visible: false
                            },
                            {
                                name: '[[[Szczegóły zadania]]]',
                                route: 'core-scheduler-details',
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Logi aplikacji]]]',
                        route: 'application-logs-list',
                        icon: 'fas fa-solid fa-info',
                        namespace: 'Ideo.Core.WMS.Common.Application.Permissions',
                        auth: { all: ['ApplicationLogsPermissions@ModuleAccess'], any: [] },
                        children: [
                            {
                                name: '[[[Treść]]]',
                                route: 'application-logs-content',
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Monitor kolejek]]]',
                        route: 'core-queues',
                        icon: 'far fa-fw fa-clock',
                        auth: { all: ['MessagePermissions@View'], any: [] },
                        children: [
                            {
                                name: "[[[Szczegóły zadania]]]",
                                route: 'core-queues-details',


                                visible: false
                            }
                        ]
                    }

                ]
            },
            {
                name: '[[[Marketplace]]]',
                icon: 'fas fa-fw fa-shop',
                namespace: 'Ideo.Core.WMS.Common.Application.Permissions',
                auth: { all: ['OtherPermissions@MarketplaceModule'], any: [] },
                children: [
                    {
                        name: '[[[Zainstalowane]]]',
                        route: 'core-marketplace-installed',
                        icon: 'fas fa-fw fa-circle-chevron-down',
                        children: [
                            {
                                name: "[[[Szczegóły]]]",
                                route: 'core-marketplace-installed-details',
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Sklep]]]',
                        route: 'core-marketplace-shop',
                        icon: 'fas fa-fw fa-download'
                    }
                ]
            },
            {
                name: '[[[Dokumentacja techniczna]]]',
                short: "[[[Dokumentacja]]]",
                route: 'admin-documentation',
                icon: 'fas fa-clipboard-list-check',
                namespace: 'Ideo.Core.WMS.Common.Application.Permissions',
                auth: { all: ['DocumentationPermissions@ModuleAccess'], any: [] }
            }
        ]
    },
    {
        name: '[[[Repozytorium plików]]]',
        short: '[[[Pliki]]]',
        route: 'filemanager',
        icon: 'fas fa-fw fa-folders',
        namespace: 'Ideo.Core.Common.Application.Permissions.Storage',
        auth: { all: ['StoragePermissions@Access'], any: [] }
    },
    {
        name: '[[[WMS]]]',
        clickable: false,
        icon:'fa-solid fa-warehouse',
        visibleInBreadcrumbs: false,
        applicationAccess: ['wms'],
        children: [
            {
                name: '[[[Akceptacje]]]',
                icon:'fa-regular fa-folder-gear',
                namespace: 'Ideo.Core.WMS.Common.Application.Permissions',
                auth: { all: ['ApprovalsPermissions@ModuleAccess'], any: [] },
                children: [
                    {
                        name: '[[[Przyjęcia awizowane]]]',
                        route: 'wms-approvals-receipts-list',
                        module: ['receipt'],
                        auth: { all: ['ApprovalsPermissions@ReceiptModule'], any: [] },
                        showCounterFromModules:  ['receipt_trade'],
                        children: [
                            {
                                name: '[[[Szczegóły]]]',
                                route: 'wms-approvals-receipts-details',
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Przyjęcia bezpośrednie]]]',
                        route: 'wms-approvals-direct-receipts-list',
                        module: ['direct_receipt'],
                        auth: { all: ['ApprovalsPermissions@DirectReceiptModule'], any: [] },
                        showCounterFromModules:  ['direct_receipt_trade'],
                        children: [
                            {
                                name: '[[[Szczegóły]]]',
                                route: 'wms-approvals-direct-receipts-details',
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Wydania]]]',
                        route: 'wms-approvals-issue-list',
                        module: ['one_stage_issue', 'collecting', 'packing', 'personal_collection', 'issue'],
                        auth: { all: ['ApprovalsPermissions@IssueModule'], any: [] },
                        showCounterFromModules:  ['issue', 'personal_collection'],
                        children: [
                            {
                                name: '[[[Szczegóły]]]',
                                route: 'wms-approvals-issue-details',
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Wydania bezpośrednie]]]',
                        route: 'wms-approvals-direct-issues-list',
                        module: ['direct_issue'],
                        auth: { all: ['ApprovalsPermissions@DirectIssueModule'], any: [] },
                        showCounterFromModules:  ['direct_issue_trade'],
                        children: [
                            {
                                name: '[[[Szczegóły]]]',
                                route: 'wms-approvals-direct-issues-details',
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Kompletacje]]]',
                        route: 'wms-approvals-sets-picking-list',
                        module: ['picking'],
                        auth: { all: ['ApprovalsPermissions@SetsModule'], any: [] },
                        showCounterFromModules:  ['picking_trade'],
                        children: [
                            {
                                name: '[[[Szczegóły]]]',
                                route: 'wms-approvals-sets-picking-form',
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Dekompletacje]]]',
                        route: 'wms-approvals-sets-disassembly-list',
                        module: ['disassembly'],
                        auth: { all: ['ApprovalsPermissions@SetsModule'], any: [] },
                        showCounterFromModules:  ['disassembly_trade'],
                        children: [
                            {
                                name: '[[[Szczegóły]]]',
                                route: 'wms-approvals-sets-disassembly-form',
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Reklamacje]]]',
                        route: 'wms-approvals-complaints-list',
                        module: ['complaints'],
                        auth: { all: ['ApprovalsPermissions@ComplaintsModule'], any: [] },
                        showCounterFromModules:  ['complaints'],
                        children: [
                            {
                                name: '[[[Szczegóły]]]',
                                route: 'wms-approvals-complaints-details',
                                visible: false
                            },
                            {
                                name: '[[[Dodaj reklamację]]]',
                                route: 'wms-approvals-complaints-create',
                                auth: { all: [], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edytuj reklamację]]]',
                                route: 'wms-approvals-complaints-edit',
                                auth: { all: [], any: [] },
                                visible: false
                            }
                        ]
                    },
                ]
            },
            {
                name: '[[[Przyjęcia]]]',
                icon: 'fa-solid fa-warehouse',
                namespace: 'Ideo.Core.WMS.Common.Application.Permissions',
                auth: { all: ['ReceiptsPermissions@ModuleAccess'], any: [] },
                children: [
                    {
                        name: '[[[Przyjęcia awizowane]]]',
                        route: 'wms-receipts-receipt-list',
                        icon: 'fa-solid fa-hands-holding-diamond',
                        module: ['receipt'],
                        auth: { all: ['ReceiptsPermissions@ReceiptModule'], any: [] },
                        showCounterFromModules:  ['receipt_warehouse'],
                        children: [
                            {
                                name: '[[[Szczegóły]]]',
                                route: 'wms-receipts-receipt-details',
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Przyjęcia bezpośrednie]]]',
                        route: 'wms-receipts-direct-receipt-list',
                        icon: 'fa-solid fa-hands-holding-diamond',
                        module: ['direct_receipt'],
                        auth: { all: ['ReceiptsPermissions@DirectReceiptModule'], any: [] },
                        showCounterFromModules:  ['direct_receipt_warehouse'],
                        children: [
                            {
                                name: '[[[Tworzenie przyjęcia bezpośredniego]]]',
                                route: 'wms-receipts-direct-receipt-form',
                                visible: false
                            },
                            {
                                name: '[[[Szczegóły]]]',
                                route: 'wms-receipts-direct-receipt-details',
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Kalendarz]]]',
                        route: 'wms-receipts-calendar',
                        icon: 'fa-solid fa-calendar-days',
                        module: ['receipt'],
                        auth: { all: ['ReceiptsPermissions@CalendarView'], any: [] },
                        children: []
                    },
                ]
            },
            {
                name: '[[[Kontrola jakości]]]',
                route: 'wms-quality-control-list',
                module: ['quality_control'],
                showCounterFromModules:  ['quality_control'],
                namespace: 'Ideo.Core.WMS.QualityControls.Application.Permissions',
                auth: { all: ['QualityControlPermissions@View'], any: [] },
                icon:'fa-solid fa-file-check',
                children: [
                    {
                        name: '[[[Szczegóły]]]',
                        route: 'wms-quality-control-details',
                        visible: false
                    },
                ]
            },
            {
                name: '[[[Komplety]]]',
                icon:'fa-solid fa-boxes-stacked',
                namespace: 'Ideo.Core.WMS.Common.Application.Permissions',
                module: ['sets'],
                auth: { all: ['SetsPermissions@ModuleAccess'], any: [] },
                children: [
                    {
                        name: '[[[Kompletacja]]]',
                        route: 'wms-sets-picking-list',
                        module: ['picking'],
                        auth: { all: ['SetsPermissions@PickingModule'], any: [] },
                        showCounterFromModules:  ['picking_warehouse'],
                        children: [
                            {
                                name: '[[[Tworzenie zlecenia kompletacji]]]',
                                route: 'wms-sets-picking-create',
                                auth: { all: ['SetsPermissions@PickingModule'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja zlecenia kompletacji]]]',
                                route: 'wms-sets-picking-edit',
                                auth: { all: ['SetsPermissions@PickingModule'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Realizacja zlecenia kompletacji]]]',
                                route: 'wms-sets-picking-regular',
                                auth: { all: ['SetsPermissions@PickingModule'], any: [] },
                                visible: false
                            },
                        ]
                    },
                    {
                        name: '[[[Dekompletacja]]]',
                        route: 'wms-sets-disassembly-list',
                        module: ['disassembly'],
                        auth: { all: ['SetsPermissions@DisassemblyModule'], any: [] },
                        showCounterFromModules:  ['disassembly_warehouse'],
                        children: [
                            {
                                name: '[[[Tworzenie zlecenia dekompletacji]]]',
                                route: 'wms-sets-disassembly-create',
                                auth: { all: ['SetsPermissions@DisassemblyModule'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja zlecenia dekompletacji]]]',
                                route: 'wms-sets-disassembly-edit',
                                auth: { all: ['SetsPermissions@DisassemblyModule'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Realizacja zlecenia dekompletacji]]]',
                                route: 'wms-sets-disassembly-regular',
                                auth: { all: ['SetsPermissions@DisassemblyModule'], any: [] },
                                visible: false
                            },
                        ]
                    },
                ]
            },
            {
                name: '[[[Przesunięcia]]]',
                icon:'fa-regular fa-arrow-right-arrow-left',
                namespace: 'Ideo.Core.WMS.Common.Application.Permissions',
                module: ['zzl','quick_transfers'],
                auth: { all: ['TransfersPermissions@ModuleAccess'], any: [] },
                children: [
                    {
                        name: '[[[Zlecenia]]]',
                        route: 'wms-transfers-list',
                        auth: { all: ['TransfersPermissions@TransferModuleAccess'], any: [] },
                        showCounterFromModules:  ['zzl', 'quick_transfers'],
                        children: [
                            {
                                name: '[[[Tworzenie zlecenia przesunięcia]]]',
                                route: 'wms-transfers-create-transfer-form',
                                auth: { all: ['TransfersPermissions@CreateTransfers'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja zlecenia przesunięcia]]]',
                                route: 'wms-transfers-edit-transfer-form',
                                auth: { all: ['TransfersPermissions@CreateTransfers'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Realizacja zlecenia przesunięcia]]]',
                                route: 'wms-transfers-regular-transfer-form',
                                visible: false
                            },
                        ]
                    },
                    {
                        name: '[[[Szybkie przesunięcia]]]',
                        route: 'wms-transfers-quick-transfer-form',
                        module: ['quick_transfers'],
                        auth: { all: ['TransfersPermissions@QuickTransfersModule'], any: [] },
                    }
                ]
            },
            {
                name: '[[[Wydania]]]',
                icon:'fa-solid fa-cart-flatbed-boxes',
                namespace: 'Ideo.Core.WMS.Common.Application.Permissions',
                auth: { all: ['IssuesPermissions@ModuleAccess'], any: [] },
                children: [
                    {
                        name: '[[[Wydania]]]',
                        route: 'wms-issue-onestage-list',
                        module: ['one_stage_issue'],
                        auth: { all: ['IssuesPermissions@OneStageIssueModule'], any: [] },
                        showCounterFromModules:  ['one_stage_issue'],
                        children: [
                            {
                                name: '[[[Wydanie]]]',
                                route: 'wms-issue-onestage-edit',
                                visible: false
                            },
                        ]
                    },
                    {
                        name: '[[[Zbieranie]]]',
                        route: 'wms-issue-collecting-list',
                        module: ['collecting'],
                        auth: { all: ['IssuesPermissions@CollectingModule'], any: [] },
                        showCounterFromModules:  ['collecting', 'aggregated_collecting'],
                        children: [
                            {
                                name: '[[[Szczegóły]]]',
                                route: 'wms-issue-collecting-edit',
                                visible: false
                            },
                            {
                                name: '[[[Zagregowane]]]',
                                route: 'wms-issue-aggregated-collecting-edit',
                                visible: false
                            },
                        ]
                    },
                    {
                        name: '[[[Pakowanie]]]',
                        route: 'wms-issue-packing-list',
                        module: ['packing'],
                        auth: { all: ['IssuesPermissions@PackingModule'], any: [] },
                        showCounterFromModules:  ['packing'],
                        children: [
                            {
                                name: '[[[Szczegóły]]]',
                                route: 'wms-issue-packing-edit',
                                visible: false
                            },
                        ]
                    },
                    {
                        name: '[[[Odbiór osobisty]]]',
                        route: 'wms-issue-personal-collecting-list',
                        module: ['personal_collection'],
                        auth: { all: ['IssuesPermissions@PersonalCollectionModule'], any: [] },
                        showCounterFromModules:  ['personal_collection_issue'],
                        children: [
                            {
                                name: '[[[Szczegóły]]]',
                                route: 'wms-issue-personal-collecting-edit',
                                visible: false
                            },
                        ]
                    },
                    {
                        name: '[[[Wydania do kurierów]]]',
                        route: 'wms-issue-couriers-list',
                        icon: 'fa-solid fa-hands-holding-diamond',
                        auth: { all: ['IssuesPermissions@CourierModule'], any: [] },
                        module: ['courier'],
                        showCounterFromModules:  ['courier'],
                        children: [
                            {
                                name: '[[[Lista dokumentów]]]',
                                route: 'wms-issue-couriers-details',
                                visible: false
                            },
                            {
                                name: '[[[Szczegóły]]]',
                                route: 'wms-issue-document-details',
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Wydania bezpośrednie]]]',
                        route: 'wms-issue-direct-issue-list',
                        icon: 'fa-solid fa-hands-holding-diamond',
                        module: ['direct_issue'],
                        auth: { all: ['IssuesPermissions@DirectIssueModule'], any: [] },
                        showCounterFromModules:  ['direct_issue_warehouse'],
                        children: [
                            {
                                name: '[[[Tworzenie wydania bezpośredniego]]]',
                                route: 'wms-issue-direct-issue-form',
                                visible: false
                            },
                            {
                                name: '[[[Szczegóły]]]',
                                route: 'wms-issue-direct-issue-details',
                                visible: false
                            }
                        ]
                    },
                ]
            },
            {
                name: '[[[Inwentaryzacja]]]',
                route: 'wms-inventories-groups',
                icon: 'fas fa-clipboard-list-check',
                module: ['standard_inventories', 'localization_inventories', 'product_inventories'],
                namespace: 'Ideo.Core.WMS.Common.Application.Permissions',
                auth: { all: ['InventoriesPermissions@ModuleAccess'], any: [] },
                showCounterFromModules:  ['standard_inventories', 'localization_inventories', 'product_inventories'],
                children: [
                    {
                        name: '[[[Tworzenie inwentaryzacji zbiorczej]]]',
                        route: 'wms-inventories-groups-add',
                        visible: false
                    },
                    {
                        name: '[[[Inwentaryzacje częściowe]]]',
                        route: 'wms-partial-inventories',
                        visible: false,
                        children: [
                            {
                                name: '[[[Szczegóły]]]',
                                route: 'wms-partial-inventories-edit',
                                visible: false
                            }
                        ]
                    },
                ]
            },
            {
                name: '[[[Zwroty]]]',
                route: 'wms-returns-list',
                icon: 'fa-solid fa-arrow-rotate-left',
                module: ['giveback'],
                namespace: 'Ideo.Core.WMS.Common.Application.Permissions',
                auth: { all: ['GivebacksPermissions@ModuleAccess'], any: [] },
                showCounterFromModules:  ['giveback'],
                children: [
                    {
                        name: '[[[Szczegóły]]]',
                        route: 'wms-returns-edit',
                        auth: { all: [], any: [] },
                        visible: false
                    },
                ]
            },
            {
                name: '[[[Reklamacje]]]',
                icon: 'fa-solid fa-calendar-circle-exclamation',
                module: ['complaints'],
                namespace: 'Ideo.Core.WMS.Common.Application.Permissions',
                auth: { all: ['ComplaintsPermissions@ModuleAccess'], any: [] },
                children: [
                    {
                        name: '[[[Przyjęcia]]]',
                        route: 'wms-complaints-receipt-list',
                        module: ['complaints'],
                        showCounterFromModules:  ['complaint_receipt'],
                        children: [
                            {
                                name: '[[[Szczegóły]]]',
                                route: 'wms-complaints-receipt-details',
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Wydania]]]',
                        route: 'wms-complaints-issue-list',
                        module: ['complaints'],
                        showCounterFromModules:  ['complaint_issue'],
                        children: [
                            {
                                name: '[[[Szczegóły]]]',
                                route: 'wms-complaints-issue-details',
                                visible: false
                            }
                        ]
                    }
                ]
            },
            {
                name: '[[[Nośniki]]]',
                route: 'wms-carriers',
                icon: 'fa-solid fa-pallet-boxes',
                namespace: 'Ideo.Core.WMS.Carriers.Application.Permissions',
                auth: { all: ['CarriersPermissions@View'], any: [] },
                licencePermissions: ['UseCarriers'],
                globalConfigurationProperty: "useCarriers",
                children: [
                    {
                        name: '[[[Nośnik]]]',
                        route: 'wms-carriers-add',
                        auth: { all: ['CarriersPermissions@Edit'], any: [] },
                        visible: false,
                        licencePermissions: ['UseCarriers']
                    },
                    {
                        name: '[[[Nośnik]]]',
                        route: 'wms-carriers-edit',
                        auth: { all: ['CarriersPermissions@Edit'], any: [] },
                        visible: false,
                        licencePermissions: ['UseCarriers']
                    },
                    {
                        name: '[[[Ustawienia nośników]]]',
                        route: 'wms-carriers-kinds',
                        auth: { all: ['CarriersPermissions@EditKinds'], any: [] },
                        visible: false,
                        licencePermissions: ['UseCarriers']
                    },
                    {
                        name: '[[[Dodaj rodzaj nośnika]]]',
                        route: 'wms-carriers-kinds-add',
                        auth: { all: ['CarriersPermissions@EditKinds'], any: [] },
                        visible: false,
                        licencePermissions: ['UseCarriers']
                    },
                    {
                        name: '[[[Edytuj rodzaj nośnika]]]',
                        route: 'wms-carriers-kinds-edit',
                        auth: { all: ['CarriersPermissions@EditKinds'], any: [] },
                        visible: false,
                        licencePermissions: ['UseCarriers']
                    }
                ]
            }
        ]
    },
    {
        name: "[[[MES]]]",
        clickable: false,
        icon: "fa-solid fa-book",
        applicationAccess: ["mes"],
        children: [
            {
                name: "[[[Receptury produkcji]]]",
                route: "mes-recipes",
                icon: "fa-solid fa-receipt",
                module: ["production_recipes"],
                namespace: "Ideo.Core.MES.ProductionRecipes.Application.Permissions",
                auth: { all: ["RecipesPermissions@View"], any: [] },
                children: [
                    {
                        name: "[[[Dodaj recepturę]]]",
                        route: "mes-recipes-add",
                        auth: { all: ["RecipesPermissions@Edit"], any: [] },
                        visible: false,
                    },
                    {
                        name: "[[[Szczegóły]]]",
                        route: "mes-recipes-edit",
                        auth: { all: ["RecipesPermissions@Edit"], any: [] },
                        visible: false,
                    },
                ],
            },
            {
                name: "[[[Plany produkcji]]]",
                route: "mes-plans",
                icon: "fa-sharp fa-thin fa-sheet-plastic",
                module: ["production_plans"],
                namespace: "Ideo.Core.MES.ProductionPlan.Application.Permissions",
                auth: { all: ["PlanPermissions@View"], any: [] },
                children: [
                    {
                        name: "[[[Dodaj]]]",
                        route: "mes-plans-add",
                        auth: { all: ["PlanPermissions@Edit"], any: [] },
                        visible: false,
                    },
                    {
                        name: "[[[Szczegóły]]]",
                        route: "mes-plans-edit",
                        auth: { all: ["PlanPermissions@Edit"], any: [] },
                        visible: false,
                    },
                ],
            },
            {
                name: "[[[Zlecenia produkcyjne]]]",
                route: "mes-orders",
                icon: "fa-sharp fa-folders",
                module: ["production_orders"],
                namespace: "Ideo.Core.MES.ProductionOrders.Application.Permissions",
                auth: { all: ["OrderPermissions@View"], any: [] },
                children: [
                    {
                        name: "[[[Dodaj]]]",
                        route: "mes-orders-add",
                        auth: { all: ["OrderPermissions@Edit"], any: [] },
                        visible: false,
                    },
                    {
                        name: "[[[Szczegóły]]]",
                        route: "mes-orders-edit",
                        auth: { all: ["OrderPermissions@Edit"], any: [] },
                        visible: false,
                    },
                ],
            },
            {
                name: "[[[Zarządzanie awariami]]]",
                route: "mes-failure",
                icon: "fa-regular fa-screwdriver-wrench",
                module: ["production_failures"],
                namespace: "Ideo.Core.MES.ProductionFailures.Application.Permissions",
                auth: { all: ["ProducstionFailurePermissions@View"], any: [] },
                children: [
                    {
                        name: "[[[Edytuj]]]",
                        route: "mes-failure-edit",
                        auth: { all: ["ProducstionFailurePermissions@Edit"], any: []},
                        visible: false,
                    },
                    {
                        name: "[[[Dodaj]]]",
                        route: "mes-failure-add",
                        auth: { all: ["ProducstionFailurePermissions@Edit"], any: []},
                        visible: false,
                    },
                ],
            },
            {
                name: "[[[Zamówienia od Klientów]]]",
                route: "mes-customer-orders",
                icon: "fa-sharp fa-regular fa-calendar",
                module: ["production_customer_orders"],
                namespace: "Ideo.Core.MES.ProductionCustomerOrders.Application.Permissions",
                auth: { all: ["ProductionCustomerOrderPermissions@View"], any: []},
                children: [
                    {
                        name: "[[[Szczegóły]]]",
                        route: "mes-customer-order-info",
                        auth: { all: ["ProductionCustomerOrderPermissions@View"], any: []},
                        visible: false,
                    },
                ],
            },
            {
                name: "[[[Panel Operatora]]]",
                route: "mes-operator-panel",
                icon: "fa-duotone fa-solid fa-gears",
                module: ["panel_operator"],
                children: [
                    {
                        name: "[[[Moje operacje]]]",
                        route: "mes-operator-panel-operations",
                        icon: "fa-solid fa-hands-holding-diamond",
                        module: ["panel_operator"],
                        children: [
                            {
                                name: "[[[Szczegóły operacji]]]",
                                route: "mes-operator-panel-operations-details",
                                visible: false,
                            },
                        ],
                        visible: false,
                    },
                    {
                        name: "[[[Awarie]]]",
                        route: "mes-operator-panel-failure",
                        icon: "fa-solid fa-hands-holding-diamond",
                        module: ["panel_operator"],
                        children: [],
                        visible: false,
                    },
                    {
                        name: "[[[Twoje przerwy]]]",
                        route: "mes-operator-panel-break",
                        icon: "fa-solid fa-hands-holding-diamond",
                        module: ["panel_operator"],
                        children: [],
                        visible: false,
                    },
                    {
                        name: "[[[Twoje zadania]]]",
                        route: "mes-operator-panel-tasks",
                        icon: "fa-solid fa-hands-holding-diamond",
                        module: ["panel_operator"],
                        children: [],
                        visible: false,
                    },
                    {
                        name: "[[[Kontrola jakości]]]",
                        route: "mes-operator-panel-quality-controls",
                        icon: "fa-solid fa-hands-holding-diamond",
                        module: ["panel_operator"],
                        visible: false,
                        children: [
                            {
                                name: "[[[Szczegóły kontroli]]]",
                                route: "mes-operator-panel-quality-controls-details",
                                icon: "fa-solid fa-hands-holding-diamond",
                                visible: false,
                            },
                        ],
                    },
                ],
            },
            {
                name: "[[[Zadania okołoprodukcyjne]]]",
                route: "mes-additional-tasks-operators",
                icon: "fa-sharp fa-solid fa-rectangle-history-circle-plus",
                module: ["addtional_tasks"],
                namespace: "Ideo.Core.MES.AdditionalTasks.Application.Permissions",
                auth: { all: ["AdditionalTasksPermissions@View"], any: [] },
                children: [
                    {
                        name: "[[[Edytuj]]]",
                        route: "mes-additional-tasks-operators-edit",
                        auth: { all: ["AdditionalTasksPermissions@Edit"], any: []},
                        visible: false,
                    },
                    {
                        name: "[[[Dodaj]]]",
                        route: "mes-additional-tasks-operators-add",
                        auth: { all: ["AdditionalTasksPermissions@Edit"], any: []},
                        visible: false,
                    },
                ],
            },
        ],
    },
    {
        name: '[[[Produkty]]]',
        route: 'wms-products-list',
        icon: 'fa-brands fa-product-hunt',
        namespace: 'Ideo.Core.WMS.Products.Application.Permissions',
        auth: { all: ['ProductsPermissions@View'], any: [] },
        children: [
            {
                name: '[[[Szczegóły]]]',
                route: 'wms-products-edit',
                visible: false
            },
        ]
    },
    {
        name: '[[[Informator]]]',
        route: 'wms-informator-search',
        icon: 'fa-solid fa-circle-info',
        namespace: 'Ideo.Core.WMS.ProductsLocalizations.Application.Permissions',
        auth: { all: ['InformatorPermissions@View'], any: [] },
    },
    {
        name: '[[[Raporty]]]',
        icon: 'fa-regular fa-file-chart-column',
        namespace: 'Ideo.Core.Features.Report.Permissions',
        children: [
            {
                name: '[[[Raporty systemowe]]]',
                route: 'wms-reports-system-list',
                auth: { all: ['ReportPermissions@ViewSystem']},
                children: [
                    {
                        name: '[[[Szczegóły]]]',
                        route: 'wms-reports-system-details',
                        visible: false
                    },
                ]
            },
            {
                name: '[[[Raporty AI]]]',
                route: 'wms-reports-ai',
                icon: 'fas fa-fw fa-database',
                auth: { all: ['ReportPermissions@ViewUser']},
                children: [
                    {
                        name: '[[[Dodaj]]]',
                        route: 'wms-reports-ai-add',
                        auth: { all: ['ReportPermissions@Add']},
                        visible: false
                    },
                    {
                        name: '[[[Edytuj]]]',
                        route: 'wms-reports-ai-view',
                        auth: { all: ['ReportPermissions@Edit']},
                        visible: false
                    },
                    {
                        name: '[[[Szczegóły]]]',
                        route: 'wms-reports-ai-execute',
                        visible: false
                    },
                ]
            },
        ]
    },
    {
        name: '[[[Konfiguracja]]]',
        icon: 'fa-solid fa-gear',
        namespace: 'Ideo.Core.WMS.Common.Application.Permissions',
        auth: { all: ['WMSConfigurationPermissions@ModuleAccess'], any: [] },
        children: [
            {
                name: '[[[Ogólne]]]',
                clickable: false,
                icon: 'fa-solid fa-book',
                children: [
                    {
                        name: '[[[Ustawienia]]]',
                        route: 'wms-settings',
                        icon: 'fas fa-fw fa-database',
                        namespace: 'Ideo.Core.WMS.GlobalConfiguration.Application.Permissions',
                        auth: { all: ['GlobalConfigurationPermissions@View'], any: [] }
                    },
                    {
                        name: '[[[Procesy]]]',
                        route: 'wms-configuration-processes',
                        icon: 'fas fa-fw fa-microchip',
                        namespace: 'Ideo.Core.Features.Configuration.Permissions',
                        auth: { all: ['ConfigurationPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Szczegóły procesu]]]',
                                route: 'wms-configuration-processes-details',
                                visible: false,
                                visibleInBreadcrumbs: false
                            }

                        ]
                    },
                    {
                        name: '[[[Drukarki]]]',
                        route: 'wms-configuration-printers',
                        icon: 'fa-solid fa-print',
                        namespace: 'Ideo.Core.WMS.Printers.Application.Permissions',
                        auth: { all: ['PrintersPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Dodaj]]]',
                                route: 'wms-configuration-printers-add',
                                auth: { all: ['PrintersPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edytuj]]]',
                                route: 'wms-configuration-printers-edit',
                                auth: { all: ['PrintersPermissions@Edit'], any: [] },
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Szablony etykiet]]]',
                        route: 'wms-configuration-printers-template',
                        icon: 'fa-solid fa-print',
                        namespace: 'Ideo.Core.WMS.Printers.Application.Permissions',
                        auth: { all: ['PrintersTemplatesPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Edytuj]]]',
                                route: 'wms-configuration-printers-template-edit',
                                auth: { all: ['PrintersTemplatesPermissions@Edit'], any: [] },
                                visible: false
                            }
                        ]
                    }
                ]
            },
            {
                name: '[[[WMS]]]',
                clickable: false,
                icon: 'fa-solid fa-gear',
                visibleInBreadcrumbs: false,
                applicationAccess: ['wms'],
                children: [
                    {
                        name: '[[[Lokalizacje]]]',
                        globalConfigurationProperty: "useLocalizations",
                        children: [
                            {
                                name: '[[[Strefy]]]',
                                route: 'wms-configuration-zones',
                                namespace: 'Ideo.Core.WMS.Localizations.Application.Permissions',
                                auth: { all: ['LocalizationsPermissions@ZonesView'], any: [] },
                                children: [
                                    {
                                        name: '[[[Dodaj]]]',
                                        route: 'wms-configuration-zones-add',
                                        auth: { all: ['LocalizationsPermissions@ZonesEdit'], any: [] },
                                        visible: false
                                    },
                                    {
                                        name: '[[[Edytuj]]]',
                                        route: 'wms-configuration-zones-edit',
                                        auth: { all: ['LocalizationsPermissions@ZonesEdit'], any: [] },
                                        visible: false
                                    },
                                    {
                                        name: '[[[Szczegóły lokalizacji]]]',
                                        route: 'wms-configuration-zones-location-edit',
                                        auth: { all: ['LocalizationsPermissions@ZonesEdit'], any: [] },
                                        visible: false
                                    },
                                    {
                                        name: '[[[Import z Excel]]]',
                                        route: 'wms-configuration-zones-import-excel',
                                        auth: { all: ['LocalizationsPermissions@ZonesEdit'], any: [] },
                                        visible: false
                                    }
                                ]
                            },
                            {
                                name: '[[[Budynki]]]',
                                route: 'wms-configuration-buildings',
                                icon: 'fa-solid fa-buildings',
                                namespace: 'Ideo.Core.WMS.Localizations.Application.Permissions',
                                auth: { all: ['LocalizationsBuildingsPermissions@View'], any: [] },
                                children: [
                                    {
                                        name: '[[[Dodaj]]]',
                                        route: 'wms-configuration-buildings-add',
                                        auth: { all: ['LocalizationsBuildingsPermissions@Edit'], any: [] },
                                        visible: false
                                    },
                                    {
                                        name: '[[[Edytuj]]]',
                                        route: 'wms-configuration-buildings-edit',
                                        auth: { all: ['LocalizationsBuildingsPermissions@Edit'], any: [] },
                                        visible: false
                                    }
                                ]
                            },
                            {
                                name: '[[[Typy stref]]]',
                                route: 'wms-configuration-zonetypes',
                                namespace: 'Ideo.Core.WMS.Localizations.Application.Permissions',
                                auth: { all: ['LocalizationsZoneTypesPermissions@ZonesView'], any: [] },
                                children: [
                                    {
                                        name: '[[[Dodaj]]]',
                                        route: 'wms-configuration-zonetypes-add',
                                        auth: { all: ['LocalizationsZoneTypesPermissions@ZonesEdit'], any: [] },
                                        visible: false
                                    },
                                    {
                                        name: '[[[Edytuj]]]',
                                        route: 'wms-configuration-zonetypes-edit',
                                        auth: { all: ['LocalizationsZoneTypesPermissions@ZonesEdit'], any: [] },
                                        visible: false
                                    }
                                ]
                            },
                            {
                                name: '[[[Rodzaje lokalizacji]]]',
                                route: 'wms-configuration-localizations-kinds',
                                namespace: 'Ideo.Core.WMS.Localizations.Application.Permissions',
                                auth: { all: ['LocalizationsKindsPermissions@View'], any: [] },
                                children: [
                                    {
                                        name: '[[[Dodaj]]]',
                                        route: 'wms-configuration-localizations-kinds-add',
                                        auth: { all: ['LocalizationsKindsPermissions@Edit'], any: [] },
                                        visible: false
                                    },
                                    {
                                        name: '[[[Edytuj]]]',
                                        route: 'wms-configuration-localizations-kinds-edit',
                                        auth: { all: ['LocalizationsKindsPermissions@Edit'], any: [] },
                                        visible: false
                                    }
                                ]
                            },
                            {
                                name: '[[[Konfiguracja ścieżki]]]',
                                route: 'configuration-tracks',
                                icon: 'fa fa-fw fa-cog',
                                namespace: 'Ideo.Core.WMS.Localizations.Application.Permissions',
                                auth: { all: ['LocalizationsPathOrderPermissions@View'], any: [] },
                                children: [
                                    {
                                        name: '[[[Konfiguracja rzędów]]]',
                                        route: 'configuration-tracks-rows',
                                        visible: false,
                                        children: [
                                            {
                                                name: '[[[Konfiguracja lokalizacji]]]',
                                                route: 'configuration-tracks-localizations',
                                                visible: false
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        name: '[[[Priorytety]]]',
                        route: 'configuration-documents-priorities',
                        icon: 'fa fa-fw fa-cog',
                        namespace: 'Ideo.Core.WMS.Common.Application.Permissions',
                        auth: { all: ['DocumentsPrioritiesPermissions@ModuleAccess'], any: [] },
                        children: [
                            {
                                name: '[[[Priorytety]]]',
                                route: 'configuration-module-documents-priorities',
                                visible: false,
                                visibleInBreadcrumbs: false
                            }
                        ]
                    },
                    {
                        name: '[[[Kody zbiorcze]]]',
                        route: 'wms-configuration-barcodes',
                        icon: 'fa-solid fa-barcode',
                        namespace: 'Ideo.Core.WMS.Products.Application.Permissions',
                        auth: { all: ['AdditionalBarcodesPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Dodaj]]]',
                                route: 'wms-configuration-barcodes-add',
                                auth: { all: ['AdditionalBarcodesPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edytuj]]]',
                                route: 'wms-configuration-barcodes-edit',
                                auth: { all: ['AdditionalBarcodesPermissionsPermissions@Edit'], any: [] },
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Kurierzy]]]',
                        route: 'wms-configuration-couriers',
                        icon: 'fa-solid fa-print',
                        namespace: 'Ideo.Core.WMS.Couriers.Application.Permissions',
                        auth: { all: ['CouriersPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Edytuj]]]',
                                route: 'wms-configuration-couriers-edit',
                                auth: { all: ['CouriersPermissions@Edit'], any: [] },
                                visible: false
                            }
                        ]
                    }
                ]
            },
            {
                name: "[[[PRODUKCJA]]]",
                clickable: false,
                icon: "fa-solid fa-book",
                applicationAccess: ["mes"],
                children: [
                    {
                        name: "[[[Pomocnicze jednostki miary]]]",
                        route: "mes-configuration-units-of-measure",
                        namespace: "Ideo.Core.MES.UnitsOfMeasure.Application.Permissions",
                        auth: { all: ["UnitsOfMeasurePermissions@View"], any: [], },
                        children: [
                            {
                                name: "[[[Edytuj]]]",
                                route: "mes-configuration-units-of-measure-edit",
                                auth: { all: ["UnitsOfMeasurePermissions@Edit"], any: [] },
                                visible: false,
                            },
                            {
                                name: "[[[Dodaj]]]",
                                route: "mes-configuration-units-of-measure-add",
                                auth: { all: ["UnitsOfMeasurePermissions@Edit"], any: [] },
                                visible: false,
                            },
                        ],
                    },
                    {
                        name: "[[[Awarie]]]",
                        route: "mes-configuration-machine-failure",
                        module: ["production_failures"],
                        namespace: "Ideo.Core.MES.Machines.Application.Permissions",
                        auth: { all: ["MachineFailurePermissions@View"], any: [] },
                        children: [
                            {
                                name: "[[[Edytuj]]]",
                                route: "mes-configuration-machine-failure-edit",
                                auth: { all: ["MachineFailurePermissions@Edit"], any: [] },
                                visible: false,
                            },
                            {
                                name: "[[[Dodaj]]]",
                                route: "mes-configuration-machine-failure-add",
                                auth: { all: ["MachineFailurePermissions@Edit"], any: [] },
                                visible: false,
                            },
                            {
                                name: "[[[Typy awarii]]]",
                                route: "mes-configuration-machine-type-failure",
                                auth: { all: ["MachineTypeFailurePermissions@View"], any: [] },
                                visible: false,
                            },
                            {
                                name: "[[[Edytuj]]]",
                                route: "mes-configuration-machine-type-failure-edit",
                                auth: { all: ["MachineTypeFailurePermissions@Edit"], any: [] },
                                visible: false,
                            },
                            {
                                name: "[[[Dodaj]]]",
                                route: "mes-configuration-machine-type-failure-add",
                                auth: { all: ["MachineTypeFailurePermissions@Edit"], any: [] },
                                visible: false,
                            },
                        ],
                    },
                    {
                        name: "[[[Maszyny]]]",
                        route: "mes-configuration-machines",
                        namespace: "Ideo.Core.MES.Machines.Application.Permissions",
                        auth: { all: ["MachinesPermissions@View"], any: [] },
                        children: [
                            {
                                name: "[[[Edytuj]]]",
                                route: "mes-configuration-machines-edit",
                                auth: { all: ["MachinesPermissions@Edit"], any: [] },
                                visible: false,
                            },
                            {
                                name: "[[[Dodaj]]]",
                                route: "mes-configuration-machines-add",
                                auth: { all: ["MachinesPermissions@Edit"], any: [] },
                                visible: false,
                            },
                            {
                                name: "[[[Grupy maszyn]]]",
                                route: "mes-configuration-machine-groups",
                                namespace: "Ideo.Core.MES.Machines.Application.Permissions",
                                auth: { all: ["MachineGroupsPermissions@View"], any: [] },
                                visible: false,
                            },
                            {
                                name: "[[[Edytuj]]]",
                                route: "mes-configuration-machine-groups-edit",
                                auth: { all: ["MachineGroupsPermissions@Edit"], any: [] },
                                visible: false,
                            },
                            {
                                name: "[[[Dodaj]]]",
                                route: "mes-configuration-machine-groups-add",
                                auth: { all: ["MachineGroupsPermissions@Edit"], any: [] },
                                visible: false,
                            },
                        ],
                    },
                    {
                        name: "[[[Stanowiska]]]",
                        route: "mes-configuration-workplaces",
                        namespace: "Ideo.Core.MES.Workplaces.Application.Permissions",
                        auth: { all: ["WorkplacesPermissions@View"], any: [] },
                        children: [
                            {
                                name: "[[[Edytuj]]]",
                                route: "mes-configuration-workplaces-edit",
                                auth: { all: ["WorkplacesPermissions@Edit"], any: [] },
                                visible: false,
                            },
                            {
                                name: "[[[Dodaj]]]",
                                route: "mes-configuration-workplaces-add",
                                auth: { all: ["WorkplacesPermissions@Edit"], any: [] },
                                visible: false,
                            },
                        ],
                    },
                    {
                        name: "[[[Zadania okołoprodukcyjne]]]",
                        route: "mes-configuration-additional-tasks",
                        module: ["addtional_tasks"],
                        namespace: "Ideo.Core.MES.AdditionalTasks.Application.Permissions",
                        auth: { all: ["AdditionalTasksPermissions@View"], any: [] },
                        children: [
                            {
                                name: "[[[Edytuj]]]",
                                route: "mes-configuration-additional-tasks-edit",
                                auth: { all: ["AdditionalTasksPermissions@Edit"], any: [] },
                                visible: false,
                            },
                            {
                                name: "[[[Dodaj]]]",
                                route: "mes-configuration-additional-tasks-add",
                                auth: { all: ["AdditionalTasksPermissions@Edit"], any: [], },
                                visible: false,
                            },
                        ],
                    },
                    {
                        name: "[[[Przerwy]]]",
                        route: "mes-configuration-break-types",
                        namespace: "Ideo.Core.MES.AdditionalTasks.Application.Permissions",
                        auth: { all: ["ProductionBreakTypesPermissions@View"], any: [] },
                        children: [
                            {
                                name: "[[[Edytuj]]]",
                                route: "mes-configuration-break-types-edit",
                                auth: { all: ["ProductionBreakTypesPermissions@Edit"],any: [] },
                                visible: false,
                            },
                            {
                                name: "[[[Dodaj]]]",
                                route: "mes-configuration-break-types-add",
                                auth: { all: ["ProductionBreakTypesPermissions@Edit"], any: [] },
                                visible: false,
                            },
                        ],
                    },
                    {
                        name: "[[[Koszty dodatkowe]]]",
                        route: "mes-configuration-additional-costs",
                        namespace: "Ideo.Core.MES.AdditionalCosts.Application.Permissions",
                        auth: { all: ["AdditionalCostsPermissions@View"], any: [] },
                        children: [
                            {
                                name: "[[[Edytuj]]]",
                                route: "mes-configuration-additional-costs-edit",
                                auth: { all: ["AdditionalCostsPermissions@Edit"], any: [] },
                                visible: false,
                            },
                            {
                                name: "[[[Dodaj]]]",
                                route: "mes-configuration-additional-costs-add",
                                auth: { all: ["AdditionalCostsPermissions@Edit"], any: []},
                                visible: false,
                            },
                        ],
                    },
                    {
                        name: "[[[Operacje]]]",
                        route: "mes-configuration-operations",
                        namespace: "Ideo.Core.MES.Operations.Application.Permissions",
                        auth: { all: ["OperationsConfigurationPermissions@View"], any: [] },
                        children: [
                            {
                                name: "[[[Edytuj]]]",
                                route: "mes-configuration-operations-edit",
                                auth: { all: ["OperationsConfigurationPermissions@Edit"],any: []},
                                visible: false,
                            },
                            {
                                name: "[[[Dodaj]]]",
                                route: "mes-configuration-operations-add",
                                auth: {all: ["OperationsConfigurationPermissions@Edit"],any: []},
                                visible: false,
                            },
                        ],
                    },
                    {
                        name: "[[[Kontrola jakości]]]",
                        route: "mes-configuration-quality-control-plans",
                        namespace: "Ideo.Core.MES.QualityControls.Application.Permissions",
                        auth: { all: ["QualityControlConfigurationPermissions@View"], any: []},
                        children: [
                            {
                                name: "[[[Edytuj]]]",
                                route: "mes-configuration-quality-control-plans-edit",
                                auth: {all: ["QualityControlConfigurationPermissions@EditPlans"],any: []},
                                visible: false,
                            },
                            {
                                name: "[[[Dodaj]]]",
                                route: "mes-configuration-quality-control-plans-add",
                                auth: {all: ["QualityControlConfigurationPermissions@EditPlans"], any: []},
                                visible: false,
                            },
                            {
                                name: "[[[Parametry kontroli jakości]]]",
                                route: "mes-configuration-quality-control-parameters",
                                namespace:"Ideo.Core.MES.QualityControls.Application.Permissions",
                                auth: {all: ["QualityControlConfigurationPermissions@View"],any: []},
                                visible: false,
                            },
                            {
                                name: "[[[Edytuj]]]",
                                route: "mes-configuration-quality-control-parameters-edit",
                                auth: {all: ["QualityControlConfigurationPermissions@EditParameters"],any: []},
                                visible: false,
                            },
                            {
                                name: "[[[Dodaj]]]",
                                route: "mes-configuration-quality-control-parameters-add",
                                auth: {all: ["QualityControlConfigurationPermissions@EditParameters"], any: []},
                                visible: false,
                            },
                        ],
                    },
                    {
                        name: "[[[Kalendarz pracy]]]",
                        route: "mes-configuration-shift-calendar",
                        namespace: "Ideo.Core.MES.ShiftsCalendar.Application.Permissions",
                        auth: { all: ["ShiftCalendarConfigurationPermissions@View"], any: []},
                    },
                    {
                        name: "[[[Identyfikatory produktów]]]",
                        route: "mes-configuration-product-identifiers",
                        namespace:"Ideo.Core.MES.Configurations.Application.Permissions",
                        auth: {all: ["SerialNumbersConfigurationPermissions@View"],any: []},
                    },
                    // {
                    //     name: '[[[Konfiguracja numerów seryjnych]]]',
                    //     route: 'mes-configuration-serial-numbers',
                    //     namespace: 'Ideo.Core.MES.Configurations.Application.Permissions',
                    //     auth: { all: ['SerialNumbersConfigurationPermissions@View'], any: [] }
                    // },
                    // {
                    //     name: '[[[Konfiguracja partii]]]',
                    //     route: 'mes-configuration-batches',
                    //     namespace: 'Ideo.Core.MES.Configurations.Application.Permissions',
                    //     auth: { all: ['BatchesConfigurationPermissions@View'], any: [] }
                    // },
                    {
                        name: "[[[Typy zleceń produkcyjnych]]]",
                        route: "mes-configuration-production-order-types",
                        namespace:"Ideo.Core.MES.ProductionOrders.Application.Permissions",
                        auth: { all: ["OrderTypesPermissions@View"], any: [] },
                        children: [
                            {
                                name: "[[[Edytuj]]]",
                                route: "mes-configuration-production-order-types-edit",
                                auth: {all: ["OrderTypesPermissions@Edit"],any: []},
                                visible: false,
                            },
                            {
                                name: "[[[Dodaj]]]",
                                route: "mes-configuration-production-order-types-add",
                                auth: {all: ["OrderTypesPermissions@Edit"],any: []},
                                visible: false,
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        name: "[[[Dodatkowe moduły]]]",
        icon: "fa-solid fa-arrow-rotate-left",
        auth: { all: [], any: [] },
        children: [],
    },
    // {
    //     name: '[[[Kalkulator pakowania]]]',
    //     route: 'packing-calculator'
    // },
];
