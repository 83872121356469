<template>
    <template v-if="item.result[configuration.field] && item.result[configuration.field].length > 0">
            <div v-for="(item, index) in items" :key="index" class="mb-1">
                <div>{{ item.text }}</div>
            </div>
    </template>
    <span v-else>
        -
    </span>
</template>

<script lang="ts" setup>
import { Resource } from '@/helpers/Interfaces';
import { DynamicGridColumn } from '../../services/DynamicGridService';
import { computed, ref } from 'vue';


interface Props
{
    configuration: DynamicGridColumn,
    item: Resource<any>
}

defineOptions({
    name: 'CollectionItemFormatter'
});

const props = withDefaults(defineProps<Props>(), {
    configuration: null,
    item: null
});

const items = computed(() =>
{
    console.log(props.item.result[props.configuration.field]);
    return props.item.result[props.configuration.field];
});

</script>
