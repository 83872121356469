<script lang="ts" setup>
import { ref } from 'vue';

const model = defineModel<any>();
const input = ref<HTMLInputElement>(null);

defineExpose({
    focus: () => input.value.focus()
});
</script>

<template>
    <textarea ref="input" v-model="model" class="form-control"></textarea>
</template>
