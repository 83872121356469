import { defineStore } from 'pinia';
import { reactive } from 'vue';
import Pager from '@/helpers/Pager';
import { Form } from '@/helpers/Form';
import { FilterModel } from '@/modules/wms/configuration/processes/services/ProcessesService';

export const useProcessesStore = defineStore('processes', () =>
{
    const filter = reactive(Form.create<FilterModel>({
        name: '',
        system: ''
    }));
    const pager = reactive(new Pager(1, 20, 'Id', 'ASC')) as Pager;

    const data = reactive(null);

    return { filter, pager, data };
});
