import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

// Errors
import Page401 from '@/views/errors/Page401.vue';
import Page403 from '@/views/errors/Page403.vue';
import Page404 from '@/views/errors/Page404.vue';
import Page500 from '@/views/errors/Page500.vue';
import Page503 from '@/views/errors/Page503.vue';

// Layouts
import EmptyLayout from '@/views/layouts/EmptyLayout.vue';
import AuthLayout from '@/views/layouts/AuthLayout.vue';
// import AuthLayout from '@/views/layouts/AuthLayoutNew.vue';
import DefaultLayout from '@/views/layouts/DefaultLayout.vue';

// Routes
import CoreRoutes from '@/modules/core/common/routes';
import SaasRoutes from '@/modules/saas/common/routes';
import StudioRoutes from '@/modules/studio/common/routes';
import WmsRoutes from '@/modules/wms/common/routes';
import AiRoutes from '@/modules/ai/common/routes';
import ReportRoutes from '@/modules/report/common/routes';
import { useLicences } from '@/plugins/licences';
import { useAlerts } from '@/plugins/alerts';
import { getCurrentInstance, Plugin } from 'vue';
import AdditionalModulesRoutes from '@/modules/wms/additional-modules/common/routes';
import MesRoutes from '@/modules/mes/common/routes';

const routes: Array<RouteRecordRaw> = [
    {
        // Strony błędów
        path: '/error',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            {
                path: '401',
                name: 'error-401',
                component: Page401
            },
            {
                path: '403',
                name: 'error-403',
                component: Page403
            },
            {
                path: '404',
                name: 'error-404',
                component: Page404
            },
            {
                path: '500',
                name: 'error-500',
                component: Page500
            },
            {
                path: '503',
                name: 'error-503',
                component: Page503
            }
        ]
    },
    {
        path: '/auth',
        redirect: '/error/404',
        component: AuthLayout,
        children: [
            {
                path: 'login',
                name: 'login',
                meta: { auth: false },
                component: () => import('@/modules/core/auth/views/Login.vue')
            },
            {
                path: 'pin-login',
                name: 'pin-login',
                meta: { auth: false },
                component: () => import('@/modules/core/auth/views/PinLogin.vue')
            },
            {
                path: 'forgot-password',
                name: 'forgot-password',
                meta: { auth: false },
                component: () => import('@/modules/core/auth/views/ForgotPassword.vue')
            },
            {
                path: 'reset-password/:token',
                name: 'reset-password',
                meta: { auth: false },
                component: () => import('@/modules/core/auth/views/ResetPassword.vue')
            },
            {
                path: 'azure',
                name: 'auth-azure',
                meta: { auth: false },
                component: () => import('@/modules/core/auth/views/AuthAzure.vue')
            },
            {
                path: 'install',
                name: 'install',
                meta: { auth: false },
                component: () => import('@/modules/core/auth/views/Install.vue')
            }
        ]
    },
    {
        path: '/',
        redirect: '/dashboard',
        component: DefaultLayout,
        children: [
            ...CoreRoutes,
            ...SaasRoutes,
            ...StudioRoutes,
            ...WmsRoutes,
            ...AiRoutes,
            ...ReportRoutes,
            ...AdditionalModulesRoutes,
            ...MesRoutes
        ]
    },
    // Ekran odbiorów osobistych
    {
        path: '/personal-collecting',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            {
                path: 'panel',
                name: 'personal-collecting-panel',
                meta: { auth: false, skipHome: true, skipSession: false },
                component: () => import('@/modules/wms/issue/personal-collecting-panel/views/PersonalCollectingPanel.vue'),
            },
        ]
    },
    {
        // 404 Not found handler
        path: '/:path(.*)*',
        redirect: '/error/404'
    }
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes
});


const RouterPlugin: Plugin =
{
    install(app, options)
    {

        router.install(app);

        router.beforeEach(async (to, from) =>
        {
            if (!app)
                return true;

            const auth = app.config.globalProperties.$auth;

            if (!auth.check())
                return true;

            const sitemap = app.config.globalProperties.$sitemap;
            const alert = app.config.globalProperties.$alert;
            const licence = app.config.globalProperties.$licence;

            const findRoute = await sitemap.find(to);

            if (!findRoute)
                return true;

            if (!findRoute.licencePermissions || findRoute.licencePermissions.length == 0)
                return true;

            const check = await licence.check(findRoute.licencePermissions);

            if (!check)
            {
                alert.warning(app.config.globalProperties.$t("[[[Nie posiadasz uprawnień do modułu w licencji]]]"));
            }

            return check;
        });

        router.isReady().then(() =>
        {
            app.mount('#app');
        });
    }
};

export default RouterPlugin;
