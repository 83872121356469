import { axios } from '@/plugins/axios';
import { Pagination, Resource, Statement } from '@/helpers/Interfaces';
import { DimensionsFormModel, WeightFormModel } from '@/modules/wms/products/services/ProductsService';
import { CarrierShortInfoModel } from '@/modules/wms/configuration/carriers/service/CarriersService';
import { OwnFieldValueShorInfo } from '@/modules/core/marketplace/services/OwnFieldsService';
import { GenerateSsccCodeResult } from '@/modules/wms/common/services/Gs1Service';
import Pager from '@/helpers/Pager';
import { merge } from 'lodash';
import { PackingVisualisationResult } from '@/modules/wms/packingCalculator/services/ContainerPackingService';


/**
 * IssueServices
 */
export default class IssueServices
{
    /**
     * @param code string
     *
     * @returns Promise<Resource<PackageInfoModel>>
     */
    public static async getOrCreate(model: PackageFormModel): Promise<Resource<PackageInfoModel>>
    {
        return (await axios.put<Resource<PackageInfoModel>>(`admin/issue/packages/getorcreate/`, model)).data;
    }

    public static async getCollectingSplitConfig(): Promise<CollectingSplitConfigModel[]>
    {
        return (await axios.get<CollectingSplitConfigModel[]>(`admin/issue/configurations/collecting-split/config`)).data;
    }

    public static async getPackerStations(): Promise<Resource<PackerStationsItemModel>[]>
    {
        return (await axios.get<Resource<PackerStationsItemModel>[]>(`admin/issue/configurations/packer-stations`)).data;
    }

    public static async getPackerStationsCoordinates(): Promise<PackerStationsCoordinatesModel[]>
    {
        return (await axios.get<PackerStationsCoordinatesModel[]>(`admin/issue/configurations/packer-stations/coordinates`)).data;
    }

    public static async savePackerStations(model: PackerStationsItemModel[]): Promise<PackerStationsItemModel[]>
    {
        return (await axios.post<PackerStationsItemModel[]>(`admin/issue/configurations/packer-stations`, model)).data;
    }

    public static async releasePackerStation(id: string): Promise<Statement>
    {
        return (await axios.post<Statement>(`admin/issue/configurations/packer-stations/${id}/release`)).data;
    }

    public static async usePackerStation(id: string): Promise<Statement>
    {
        return (await axios.post<Statement>(`admin/issue/configurations/packer-stations/${id}/use`)).data;
    }

    public static async myPackerStation(): Promise<Resource<PackerStationInfoModel>>
    {
        return (await axios.post<Resource<PackerStationInfoModel>>(`admin/issue/configurations/packer-stations/my`)).data;
    }

    public static async getRealizationDocumentForZk(zkPublicId: string): Promise<DocumentRealizingShortInfo[]>
    {
        return (await axios.get<DocumentRealizingShortInfo[]>(`admin/issue/${zkPublicId}/get-realizing-documents-for-zk`)).data;
    }

    public static async getOwnFieldsValuesForZk(zkPublicId: string): Promise<OwnFieldValueShorInfo[]>
    {
        return (await axios.get<OwnFieldValueShorInfo[]>(`admin/issue/${zkPublicId}/get-own-fields-values-for-zk`)).data;
    }

    public static async canEndWithoutShipments(id: string): Promise<boolean>
    {
        return (await axios.get<boolean>(`admin/issue/${id}/can-end-without-shipments`)).data;
    }

    public static async canRemoveQueue(queueId: string): Promise<Resource<boolean>>
    {
        return (await axios.get<Resource<boolean>>(`admin/queues/${queueId}/can-be-delete`)).data;
    }
    
    public static async getProposedPackages(id: string, pager: Pager): Promise<Pagination<Resource<PackagePropositionListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<PackagePropositionListItemModel>>>(`admin/issue/packages/propositions/${id}`, {
            params: merge({}, pager.data())
        })).data;
    }

    public static async getVisualisationResult(documentPublicId: string, carrierPublicId: string, quantity: number): Promise<PackingVisualisationResult>
    {
        return (await axios.get<PackingVisualisationResult>(`admin/issue/packages/visualization`, {
            params:
            {
                documentPublicId,
                carrierPublicId,
                quantity
            }
        })).data;
    }
}

export interface PackageFormModel
{
    packageCode: string
    ssccGenerateCodeResult: GenerateSsccCodeResult
}

export interface PackageInfoModel
{
    publicId: string
    code: string
    carrier: CarrierShortInfoModel
    weight: WeightFormModel
    dimensions: DimensionsFormModel
}
export interface CollectingSplitConfigModel
{
    type: string
    name: string
    properties: CollectingSplitConfigPropertyModel[]
}
export interface CollectingSplitConfigPropertyModel
{
    name: string
    key: string
    type: string
    defaultValue: string
}
export interface PackerStationsItemModel
{
    publicId: string
    name: string
    accessCode: string
    packerStationLocalizations: {
        zonePublicId: string
        localizationPublicId: string
    }[]
    packerStationPrinters: string[]
    packerStationCoordinates: PackerStationCoordinates[]
    packerStationUser?: {
        dateStartUtc: string
        user: {
            publicId: string
            fullName: string
        }
    }
}
export interface PackerStationCoordinates {
    type: string
    args: Record<string, string>
}
export interface PackerStationsCoordinatesModel
{
    type: string
    name: string
    properties: {
        name: string
        key: string
        order: number
        type: string
    }[]
}

export interface PackerStationInfoModel {
    publicId: string
    name: string
    localizationPublicId: string
    localization: string
    zonePublicId: string
    zone: string
}

export interface DocumentRealizingShortInfo {
    dateCreatedUtc: string;
    realizingDocumentExternalNumber: string;
    realizingDocumentSyncId: string;
}

export interface PackagePropositionListItemModel
{
    carrier: CarrierShortInfoModel
    suggestedQuantityForAllPositions: number
    moreThanFifteenForAllPositions: boolean
    toSmallContainerToPackAllPositions: boolean

    suggestedQuantityForNoPackedPositions: number
    moreThanFifteenForNoPackedPositions: boolean
    toSmallContainerToPackNoPackedPositions: boolean
}


