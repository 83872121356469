<script lang="ts" setup>
import { ref, computed, onMounted, nextTick, watch } from 'vue';
import { normalizeClasses } from '@/helpers/Utils';

defineOptions({
    name: 'ideo-modal',
    inheritAttrs: false
});

const props = withDefaults(defineProps<{
    modelValue?: boolean;
    title?: string;
    static?: boolean;
    centered?: boolean;
    scrollable?: boolean;
    fullscreen?: boolean;
    size?: 'sm'|'md'|'lg'|'xl'|'xxl';
    hideHeader?: boolean;
    hideFooter?: boolean;
    hideClose?: boolean;
    modalClass?: Record<string, boolean> | string[] | string;
    backdropClass?: Record<string, boolean> | string[] | string;
    contentClass?: Record<string, boolean> | string[] | string;
    headerClass?: Record<string, boolean> | string[] | string;
    bodyClass?: Record<string, boolean> | string[] | string;
    footerClass?: Record<string, boolean> | string[] | string;
}>(), {
    title: null,
    size: 'md',
    modalClass: () => ({}),
    backdropClass: () => ({}),
    contentClass: () => ({}),
    headerClass: () => ({}),
    bodyClass: () => ({}),
    footerClass: () => ({}),
});

const emit = defineEmits<{
    (e: 'ok'): void;
    (e: 'close'): void;
    (e: 'hidden'): void;
    (e: 'shown'): void;
    (e: 'update:modelValue', value: boolean): void;
}>();

const isMounted = ref(false);
const isVisible = ref(false);

const backdropClasses = computed(() => ({
    'd-none': !isVisible.value,
    ...normalizeClasses(props.backdropClass)
}));

const modalClasses = computed(() => ({
    'd-block': isVisible.value,
    ...normalizeClasses(props.modalClass)
}));

const flag = (value: any): boolean =>
{
    return value !== false;
};

const dialogClasses = computed(() => ({
    'modal-dialog-centered': flag(props.centered),
    'modal-dialog-scrollable': flag(props.scrollable),
    'modal-fullscreen': flag(props.fullscreen),
    [`modal-${props.size}`]: props.size != 'md'
}));

const contentClasses = computed(() => normalizeClasses(props.contentClass));
const headerClasses = computed(() => normalizeClasses(props.headerClass));
const bodyClasses = computed(() => normalizeClasses(props.bodyClass));
const footerClasses = computed(() => normalizeClasses(props.footerClass));

onMounted(() =>
{
    isMounted.value = true;
});

const updateModel = (): void =>
{
    emit('update:modelValue', isVisible.value);
};

const hide = (): void =>
{
    isVisible.value = false;
    updateModel();

    nextTick(() =>
    {
        emit('hidden');
    });
};

const show = (): void =>
{
    isVisible.value = true;
    updateModel();

    nextTick(() =>
    {
        emit('shown');
    });
};

const onOk = (): void =>
{
    hide();
    emit('ok');
};

const onClose = (): void =>
{
    hide();
    emit('close');
};

watch(() => props.modelValue, (value: boolean, old: boolean) =>
{
    if (value != old)
        isVisible.value = value;
}, { immediate: true });

const toggle = (): void =>
{
    if (isVisible.value)
        hide();
    else
        show();
};

const onOutOfFocus = (): void =>
{
    if (!flag(props.static))
        hide();
};

defineExpose({
    toggle,
    show,
    hide
});
</script>

<template>
    <teleport to="body" v-if="isMounted && isVisible">
        <div class="modal-backdrop" :class="backdropClasses"></div>
        <div class="modal" v-bind="$attrs" :class="modalClasses" @mousedown.self="onOutOfFocus">
            <div class="modal-dialog" :class="dialogClasses">
                <div class="modal-content" :class="contentClasses" @click.stop>
                    <div class="modal-header" :class="headerClasses" v-if="!flag(hideHeader)">
                        <slot name="modal-header">
                            <h5 class="modal-title">{{ title }}</h5>
                            <button type="button" class="close" @click.stop="onClose" v-if="!flag(hideClose)">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </slot>
                    </div>
                    <div class="modal-body" :class="bodyClasses">
                        <slot name="default"></slot>
                    </div>
                    <div class="modal-footer" :class="footerClasses" v-if="!flag(hideFooter)">
                        <slot name="modal-footer" :ok="() => onOk()" :cancel="() => onClose()">
                            <button type="button" class="btn btn-secondary" @click.stop="onClose">
                                <slot name="btn-close">
                                    {{ $t('[[[Zamknij]]]') }}
                                </slot>
                            </button>
                            <button type="button" class="btn btn-primary" @click.stop="onOk">
                                <slot name="btn-submit">
                                    {{ $t('[[[Ok]]]') }}
                                </slot>
                            </button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>

<style lang="scss">
.modal-backdrop {
    opacity: 0.5;
    z-index: 10312 !important;
}

.modal {
    overflow-x: hidden !important;
    overflow-y: auto !important;
    z-index: 10313 !important;

    button.close {
        background-color: transparent;
        border: 0;
        font-size: 1.21875rem;
        font-weight: 700;
        line-height: 1;
    }
}

.modal-dialog-sm {
    max-width: 300px !important;
}

.modal-dialog-lg {
    max-width: 800px !important;
}

.modal-dialog-xl {
    max-width: 1140px !important;
}

.modal-footer,
.modal-header {
    background-color: var(--bs-body-bg) !important;
}

.modal-footer {
    &--inside-body {
        margin: 0 calc(var(--bs-modal-padding) * -1) calc(var(--bs-modal-padding) * -1);
    }
}

@media screen and (max-width: 900px) {
    .modal-dialog {
        margin: var(--bs-modal-margin) auto !important;
    }
}

@media screen and (max-width: 800px) {
    .modal-dialog {
        .modal-content {
            width: 93%;
            margin: 0 auto;
        }
    }
}
</style>
